import {Routes} from '@angular/router';
import {GuestService} from './services/guest.service';
import {MainLayoutComponent} from './components/layouts/main-layout/main-layout.component';
import {AuthService} from './services/auth.service';
import {WithdrawActivateComponent} from './views/withdraw-activate/withdraw-activate.component';
import {VoucherMiddlewareComponent} from './views/sessions/voucher-middleware/voucher-middleware.component';

export const rootRouterConfig: Routes = [
    {
        path: 'withdraw',
        component: MainLayoutComponent,
        children: [
            {
                path: ':token',
                component: WithdrawActivateComponent
            }
        ],
        data: {}
    },
    {
        path: 'voucher-middleware',
        component: VoucherMiddlewareComponent
    },
    {
        path: '',
        component: MainLayoutComponent,
        canActivate: [AuthService],
        loadChildren: './views/main/main.module#MainModule',
        data: {role: 'user'},
    },
    {
        path: 'page',
        component: MainLayoutComponent,
        loadChildren: './views/pages/pages.module#PagesModule'
    },
    {
        path: 'sessions',
        component: MainLayoutComponent,
        canActivate: [GuestService],
        loadChildren: './views/sessions/sessions.module#SessionsModule'
    },
    {
        path: 'payment',
        component: MainLayoutComponent,
        loadChildren: './views/payment/payment.module#PaymentModule'
    },
    {
        path: 'admin',
        component: MainLayoutComponent,
        canActivate: [AuthService],
        loadChildren: './views/admin/admin.module#AdminModule',
        data: {role: 'admin'},
    },
    {
        path: 'admin-kyc',
        component: MainLayoutComponent,
        canActivate: [AuthService],
        loadChildren: './views/admin-kyc/admin-kyc.module#AdminKycModule',
        data: {role: 'admin'},
    },
    {
        path: ':lang/sessions',
        component: MainLayoutComponent,
        canActivate: [GuestService],
        loadChildren: './views/sessions/sessions.module#SessionsModule',
    },
    {
        path: 'user',
        component: MainLayoutComponent,
        canActivate: [AuthService],
        loadChildren: './views/profile/profile.module#ProfileModule',
        data: {role: 'user'},
    },
    {
        path: '**',
        redirectTo: 'sessions/404'
    }
];

