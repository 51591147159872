import {Injectable} from '@angular/core';


declare var document: any;

@Injectable()
export class DynamicScriptLoaderService {

    constructor() {

    }

    load(src: string) {
        return new Promise((resolve, reject) => {
            // load script
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = src;
            if (script.readyState) { // IE
                script.onreadystatechange = () => {
                    if (script.readyState === 'loaded' || script.readyState === 'complete') {
                        script.onreadystatechange = null;
                        resolve({status: 'Loaded'});
                    }
                };
            } else { // Others
                script.onload = () => {
                    resolve({status: 'Loaded'});
                };
            }
            script.onerror = (error: any) => resolve({status: 'Not Loaded'});
            document.getElementsByTagName('head')[0].appendChild(script);
        });
    }

    loadCC(userData) {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        const inlineCode = document.createTextNode(
            'var wpwlOptions = {\n' +
            '"locale": "en-US"' +
            '};');

        script.appendChild(inlineCode);

        document.getElementsByTagName('head')[0].appendChild(script);
    }

    loadCC1(userData) {
        return new Promise((resolve, reject) => {
            // load script
            const script = document.createElement('script');
            script.type = 'text/javascript';
            const inlineCode = document.createTextNode(
                'var wpwlOptions = {\n' +
                `"customer.givenName": "${userData.firstName}",\n` +
                `"customer.surname": "${userData.lastName}"\n` +
                '};');

            script.appendChild(inlineCode);

            document.getElementsByTagName('head')[0].appendChild(script);
            resolve({status: 'Loaded1'});
        });
    }


}
