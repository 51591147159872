import {Injectable} from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';
import * as moment from 'moment';

@Injectable()
export class AdminService {
    private readonly hostUrl: string;

    constructor(private networkHelper: NetworkHelper, private storage: StorageService) {
        this.hostUrl = environment.host;
    }

    searchUser(query: any, page: number, itemsPerPage: number, sortBy: string): Observable<any> {
        query.page = page;
        query.itemsPerPage = itemsPerPage;
        query.sortBy = sortBy;
        if (query.status === 'any') {
            delete query.status;
        }
        if (query.verified === 'any') {
            delete query.verified;
        }
        if (query.isBlocked === 'any') {
            delete query.isBlocked;
        }
        if (query.kycTier === 'any') {
            delete query.kycTier;
        }
        if (query.kycSubmitted === 'any') {
            delete query.kycSubmitted;
        }
        if (query.kycProviderStatus === 'any') {
            delete query.kycProviderStatus;
        }
        if (query.search === '') {
            delete query.search;
        }

        return this.networkHelper.post(`${this.hostUrl}/user/search`, query);
    }

    searchUserRef(query: any, userId: string, page: number, itemsPerPage: number, sortBy: string): Observable<any> {
        query.page = page;
        query.itemsPerPage = itemsPerPage;
        query.referrerId = userId;
        query.sortBy = sortBy;

        if (query.status === 'any') {
            delete query.status;
        }
        if (query.verified === 'any') {
            delete query.verified;
        }
        if (query.isBlocked === 'any') {
            delete query.isBlocked;
        }
        if (query.kycTier === 'any') {
            delete query.kycTier;
        }
        if (query.kycSubmitted === 'any') {
            delete query.kycSubmitted;
        }
        if (query.kycProviderStatus === 'any') {
            delete query.kycProviderStatus;
        }
        if (query.search === '') {
            delete query.search;
        }

        return this.networkHelper.post(`${this.hostUrl}/user/search`, query);
    }

    addNewReferrer(data): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/admin/add-referrer`, data);
    }

    reactToKYCSubmission(userId: string, action: 'approve' | 'disapprove' | 'reset', tier: number, reason?: string): Observable<any> {
        const data: any = {
        };

        if (tier >= 0) {
            data.kycTier = tier;
        }
        if (reason) {
            data.reason = reason;
        }

        return this.networkHelper.post(`${this.hostUrl}/user/kyc/${userId}/${action}`, data);
    }

    videoKycStatusChange(userId: string, action: 'approve' | 'disapprove' | 'initiate'): Observable<any> {
        const data: any = {
        };
        return this.networkHelper.post(`${this.hostUrl}/user/video-kyc/${userId}/${action}`, data);
    }

    getGroup(type): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/admin/group/${type}`);
    }

    addGroup(data): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/admin/add-group`, data);
    }

    adjust(data): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/admin/adjustment`, data);
    }

    getTradesAmount(startDate, endDate, symbol): Observable<any> {
        const query = {
            startDate: startDate,
            endDate: endDate
        };

        if (symbol) {
            query['symbol'] = symbol;
        }
        query['timeZoneShift'] = moment().utcOffset();
        return this.networkHelper.get(`${this.hostUrl}/admin/trades-amount`, query);
    }

    getTradesFees(startDate, endDate, symbol = null): Observable<any> {
        const query = {
            startDate: startDate,
            endDate: endDate
        };

        if (symbol) {
            query['symbol'] = symbol;
        }
        query['timeZoneShift'] = moment().utcOffset();
        return this.networkHelper.get(`${this.hostUrl}/admin/trades-fees`, query);
    }

    getMarginFees(startDate, endDate, symbol = null): Observable<any> {
        const query = {
            startDate: startDate,
            endDate: endDate
        };

        if (symbol) {
            query['symbol'] = symbol;
        }
        query['timeZoneShift'] = moment().utcOffset();
        return this.networkHelper.get(`${this.hostUrl}/admin/margin-fees`, query);
    }

    getTradesCount(startDate, endDate, symbol = null): Observable<any> {
        const query = {
            startDate: startDate,
            endDate: endDate
        };

        if (symbol) {
            query['symbol'] = symbol;
        }
        query['timeZoneShift'] = moment().utcOffset();
        return this.networkHelper.get(`${this.hostUrl}/admin/trades-count`, query);
    }
    tradesCountAllCsv(startDate, endDate): Observable<any> {
        const query = {
            startDate: startDate,
            endDate: endDate
        };

        query['timeZoneShift'] = moment().utcOffset();
        return this.networkHelper.get(`${this.hostUrl}/admin/csv/trades-count-all`, query);
    }

    tradesAmountAllCsv(startDate, endDate): Observable<any> {
        const query = {
            startDate: startDate,
            endDate: endDate
        };

        query['timeZoneShift'] = moment().utcOffset();
        return this.networkHelper.get(`${this.hostUrl}/admin/csv/trades-amount-all`, query);
    }

    tradesFeesAllCsv(startDate, endDate): Observable<any> {
        const query = {
            startDate: startDate,
            endDate: endDate
        };

        query['timeZoneShift'] = moment().utcOffset();
        return this.networkHelper.get(`${this.hostUrl}/admin/csv/trades-fees-all`, query);
    }

    marginFeesAllCsv(startDate, endDate): Observable<any> {
        const query = {
            startDate: startDate,
            endDate: endDate
        };

        query['timeZoneShift'] = moment().utcOffset();
        return this.networkHelper.get(`${this.hostUrl}/admin/csv/margin-fees-all`, query);
    }

    depositFeesAllCsv(startDate, endDate): Observable<any> {
        const query = {
            startDate: startDate,
            endDate: endDate
        };

        query['timeZoneShift'] = moment().utcOffset();
        return this.networkHelper.get(`${this.hostUrl}/admin/csv/deposit-fees-all`, query);
    }

    withdrawFeesAllCsv(startDate, endDate): Observable<any> {
        const query = {
            startDate: startDate,
            endDate: endDate
        };

        query['timeZoneShift'] = moment().utcOffset();
        return this.networkHelper.get(`${this.hostUrl}/admin/csv/withdraw-fees-all`, query);
    }

    getSummary(symbol): Observable<any> {
        const tzShift = moment().utcOffset();
        return this.networkHelper.get(`${this.hostUrl}/admin/summary/${symbol}/${tzShift}`);
    }

    getPayouts(status, currency, page, itemPerPage, startDate, endDate): Observable<any> {
        const data = {
            status: status,
            currency: currency,
            page: page,
            itemsPerPage: itemPerPage,
            startDate: startDate,
            endDate: endDate
        };
        return this.networkHelper.get(`${this.hostUrl}/admin/payouts`, data);
    }

    getPendingPayoutsCount(startDate): Observable<any> {
        const data = {
            startDate: startDate
        };
        return this.networkHelper.get(`${this.hostUrl}/admin/pending-payouts-count`, data);
    }

    getPendingKYCCount(startDate): Observable<any> {
        const data = {
            startDate: startDate
        };
        return this.networkHelper.get(`${this.hostUrl}/admin/pending-kyc-count`, data);
    }

    getPayoutsCsv(status, currency, startDate, endDate): Observable<any> {
        const data = {
            status: status,
            currency: currency,
            startDate: startDate,
            endDate: endDate
        };

        data['timeZoneShift'] = moment().utcOffset();

        return this.networkHelper.get(`${this.hostUrl}/admin/csv/payouts`, data);
    }

    getBalancesCsv(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/admin/csv/balances`, {});
    }

    approvePayout(payoutId): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/admin/payouts/${payoutId}`, {});
    }

    rejectPayout(payoutId, reason): Observable<any> {
        return this.networkHelper.patch(`${this.hostUrl}/admin/payouts/${payoutId}`, { reason: reason });
    }

    failedPayout(payoutId): Observable<any> {
        return this.networkHelper.put(`${this.hostUrl}/admin/payouts/${payoutId}`, {});
    }

    refundPayout(payoutId: string): Observable<any> {
        return this.networkHelper.put(`${this.hostUrl}/admin/payouts-refund/${payoutId}`, {});
    }

    updatePayoutStatus(payoutId: string, status?: any) {
        const body: any = {};
        if (status) {
            body.status = status;
        }

        return this.networkHelper.put(`${this.hostUrl}/admin/payouts/${payoutId}`, body);
    }

    blockUserWithdrawal(userId: string): Observable<any> {
        return this.networkHelper.put(`${this.hostUrl}/user/${userId}/block-withdrawal`, {});
    }

    unblockUserWithdrawal(userId: string): Observable<any> {
        return this.networkHelper.put(`${this.hostUrl}/user/${userId}/unblock-withdrawal`, {});
    }

    suspendUser(userId: string): Observable<any> {
        return this.networkHelper.patch(`${this.hostUrl}/user/suspend/${userId}`, {});
    }

    unsuspendUser(userId: string): Observable<any> {
        return this.networkHelper.patch(`${this.hostUrl}/user/unsuspend/${userId}`, {});
    }        
}
