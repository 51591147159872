import {Component, NgZone, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {DataService} from '../../../services/data.service';
import {TranslateService} from '@ngx-translate/core';
import {VoucherManager} from '../../../services/voucher-manager';

@Component({
    selector: 'app-voucher-middleware',
    template: ''
})
export class VoucherMiddlewareComponent implements OnInit {
    token: string;

    constructor(public translate: TranslateService,
                private route: ActivatedRoute,
                private snackBar: MatSnackBar,
                private authService: AuthService,
                private dataService: DataService,
                private voucherManager: VoucherManager,
                private router: Router,
                private zone: NgZone) {
    }

    ngOnInit() {
        this.route.queryParamMap.subscribe((params: ParamMap) => {
            if (params.get('vendor-action') &&
                params.get('vendor-id') &&
                params.get('vendor-url')) {
                if (!VoucherManager.vendorActions.includes(params.get('vendor-action'))) {
                    return;
                }

                const vendorParams: any = {};
                for (const param of params.keys) {
                    vendorParams[param] = params.get(param);
                }

                let lang = params.get('lang');
                if (!lang || !lang.length) {
                    lang = 'en';
                }

                localStorage.setItem('lang', lang);
                localStorage.setItem('vendorAction', params.get('vendor-action'));
                localStorage.setItem('vendorId', params.get('vendor-id'));
                localStorage.setItem('vendorURL', params.get('vendor-url'));
                localStorage.setItem('vendorParams', JSON.stringify(vendorParams));
            }
            this.zone.run(() => {
                this.router.navigate(['/sessions/signin']);
            });
        });
    }
}
