<div class="dialog-container fx-all-100 layout-all-col-nowrap">
    <div class="header-box flex layout-all-row-wrap align-all-spcBtw-ctr">
        <div class="header-text fx-all-100">
            <p>{{'BUY' | translate}} {{wallet?.currency}}</p>
        </div>
    </div>

    <div class="content-box fx-all-100 layout-all-row-wrap">
        <div class="fx-all-100 layout-all-col-nowrap">
            <div class="flex">
                <mat-form-field class="fx-all-100">
                    <input matInput
                           id="amount"
                           type="number"
                           name="amount"
                           placeholder="{{'AMOUNT' | translate}} ({{wallet?.currency}})"
                           amountDirective
                           [currency]="wallet.currency"
                           [(ngModel)]="amount"
                           [formControl]="amountControl">
                </mat-form-field>
            </div>
            <div class="flex">
                <mat-form-field class="fx-all-100">
                    <input matInput
                           id="price"
                           class="form-group"
                           type="number"
                           name="price"
                           placeholder="{{'TOTAL' | translate}} (EUR)"
                           [(ngModel)]="total"
                           [formControl]="totalControl"
                           readonly="{{isTotalReadonly}}">
                </mat-form-field>
            </div>
            <div class="fx">
                <p>1 {{wallet?.currency}} = {{rate | amount: 'EUR'}} EUR</p>
            </div>
        </div>
    </div>

    <div class="actions-box fx-all-100 layout-all-row-nowrap align-all-fxEnd-fxStr">
        <div class="fx fill-0-1">
            <button mat-raised-button
                    class="reject-button-filled shadowed tall-button"
                    (click)="close()">
                {{'EXCHANGE.ADMIN.SETTINGS.CANCEL' | translate}}
            </button>
        </div>
        <div class="fx fill-0-1 offset-all-4">
            <button mat-raised-button
                    class="approve-button-filled shadowed tall-button"
                    [disabled]="isBuyDisabled"
                    (click)="onBuy()">
                {{ 'BUY' | translate }}
            </button>
        </div>
    </div>
</div>
