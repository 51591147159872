import {Component, Input, OnInit} from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-admin-header',
    templateUrl: './admin-header.component.html'
})
export class AdminHeaderComponent implements OnInit {
    @Input() isMobile: boolean = false;
    items = [];
    itemsArray = [
        {
            'link': '/admin',
            'materialicon': 'account_balance_wallet',
            'name': 'EXCHANGE.ADMIN.LAYOUT_MENU.DASHBOARD',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/admin/users',
            'materialicon': 'account_circle',
            'name': 'EXCHANGE.ADMIN.LAYOUT_MENU.USERS',
            'exact': false,
            'enabled': true
        },
        {
            'link': '/admin/trades',
            'materialicon': 'history',
            'name': 'EXCHANGE.ADMIN.LAYOUT_MENU.TRADES',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/admin/order-history',
            'materialicon': 'history',
            'name': 'EXCHANGE.ADMIN.LAYOUT_MENU.ORDER_HISTORY',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/admin/open-orders',
            'materialicon': 'access_time',
            'name': 'EXCHANGE.ADMIN.LAYOUT_MENU.OPEN_ORDERS',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/admin/payouts',
            'materialicon': 'payment',
            'name': 'EXCHANGE.ADMIN.LAYOUT_MENU.PAYOUTS',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/admin/transactions',
            'materialicon': 'access_time',
            'name': 'EXCHANGE.ADMIN.LAYOUT_MENU.TRANSACTIONS',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/admin/margin-trading',
            'materialicon': 'history',
            'name': 'EXCHANGE.ADMIN.LAYOUT_MENU.MARGIN_TRADING',
            'exact': true,
            'enabled': environment.marginTrading.enabled
        },
        {
            'link': '/admin/mt5',
            'materialicon': 'account_balance_wallet',
            'name': 'EXCHANGE.ADMIN.LAYOUT_MENU.MT5',
            'exact': true,
            'enabled': environment.mt5.enabled
        },
        {
            'link': '/admin/affiliate',
            'materialicon': 'people_outline',
            'name': 'EXCHANGE.USER.AFFILIATE',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/admin/settings',
            'materialicon': 'settings',
            'name': 'EXCHANGE.ADMIN.LAYOUT_MENU.SETTINGS',
            'exact': false,
            'enabled': true
        }
    ];

    constructor(private storage: StorageService) {
        for (const item of this.itemsArray) {
            if (item.enabled) {
                this.items.push(item);
            }
        }
    }

    ngOnInit() {

    }

}
