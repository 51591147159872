<div class="dialog-container fx-all-100 layout-all-col-nowrap">
    <div class="content-box fx-all-100 layout-all-row-wrap">
        <!-- {{"EXCHANGE.MODALS.DELETE_ALL_CONFIRMATION" | translate}} -->
        {{reasonText}}
    </div>
    <div class="actions-box fx-all-100 layout-all-row-nowrap align-all-fxEnd-fxStr">
        <div class="fx fill-0-1">
            <button mat-raised-button
                    class="reject-button-filled shadowed tall-button"
                    (click)="cancel()">
                {{"EXCHANGE.MODALS.CANCEL" | translate}}
            </button>
        </div>
        <div class="fx fill-0-1 offset-all-1">
            <button mat-raised-button
                    class="approve-button-filled shadowed tall-button"
                    (click)="confirm()">
                {{"EXCHANGE.MODALS.CONFIRM" | translate}}
            </button>
        </div>
    </div>
</div>
