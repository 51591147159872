import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';

import {
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTooltipModule
} from '@angular/material';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MainLayoutComponent} from './layouts/main-layout/main-layout.component';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';

import {ClipboardDirective} from '../directives/clipboard.directive';
import {InfoSnackBarComponent} from './info-snackbar/info-snackbar.component';
import {ErrorSnackBarComponent} from './error-snackbar/error-snackbar.component';
import {SuccessSnackBarComponent} from './success-snackbar/success-snackbar.component';
import {ReasonComponent} from './disapprove-modal/disapprove-modal.component';
import {UserHeaderComponent} from './headers/user-header/user-header.component';
import {AdminHeaderComponent} from './headers/admin-header/admin-header.component';
import {KycAdminHeaderComponent} from './headers/kyc-admin-header/kyc-admin-header.component';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import {ApproveCancelComponent} from './approve-cancel/approve-cancel.component';
import {CommonPipesModule} from '../pipes/common/common-pipes.module';
import {ChartsModule} from 'ng2-charts';
import {ResponsiveMenuComponent} from './responsive-menu/responsive-menu.component';
import {BarChartComponent} from './chart/bar-chart.component';
import {LineChartComponent} from './chart/line-chart.component';
import {PieChartComponent} from './chart/pie-chart.component';
import {PositionTableComponent} from './position-table/position-table.component';
import {TableBaseComponent} from './table-base/table-base.component';
import {ActivePositionsTableComponent} from './active-positions-table/active-positions-table.component';
import {SelectFilterComponent} from './select-filter/select-filter.component';
import {ProvideDestinationTagComponent} from './provide-destination-tag/provide-destination-tag.component';
import {AmountFormatterDirective} from '../directives/amount.directive';
import {PriceFormatterDirective} from '../directives/price.directive';
import {QuantityFormatterDirective} from '../directives/quantity.directive';
import {TvChartContainerComponent} from './tv-chart-container/tv-chart-container.component';
import {RedeemCgiftComponent} from './redeem-cgift/redeem-cgift.component';
import {ClickOutsideDirective} from '../directives/click-outside.directive';
import {ClickElsewhereDirective} from '../directives/click-elsewhere.directive';
import {CheckByProviderComponent} from './check-by-provider-modal/check-by-provider-modal.component';
import {MultipleSelectComponent} from './multiple-select/multiple-select.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { BuyCurrencyComponent } from './buy-currency/buy-currency.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        FlexLayoutModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatTooltipModule,
        MatOptionModule,
        MatSelectModule,
        MatMenuModule,
        MatSnackBarModule,
        MatButtonModule,
        MatRadioModule,
        MatChipsModule,
        MatCheckboxModule,
        MatCardModule,
        MatDialogModule,
        MatInputModule,
        MatDatepickerModule,
        MatPaginatorModule,
        NgxMatSelectSearchModule,
        HttpClientModule,
        TranslateModule,
        CommonPipesModule,
        ChartsModule
    ],
    declarations: [
        MainLayoutComponent,
        ClipboardDirective,
        ClickOutsideDirective,
        ClickElsewhereDirective,
        InfoSnackBarComponent,
        ErrorSnackBarComponent,
        SuccessSnackBarComponent,
        ReasonComponent,
        UserHeaderComponent,
        AdminHeaderComponent,
        KycAdminHeaderComponent,
        MaintenanceComponent,
        ApproveCancelComponent,
        ResponsiveMenuComponent,
        LineChartComponent,
        BarChartComponent,
        PieChartComponent,
        TableBaseComponent,
        PositionTableComponent,
        ActivePositionsTableComponent,
        SelectFilterComponent,
        ProvideDestinationTagComponent,
        AmountFormatterDirective,
        PriceFormatterDirective,
        QuantityFormatterDirective,
        TvChartContainerComponent,
        RedeemCgiftComponent,
        CheckByProviderComponent,
        MultipleSelectComponent,
        NotificationsComponent,
        BuyCurrencyComponent,
    ],
    providers: [],
    entryComponents: [
        InfoSnackBarComponent,
        ErrorSnackBarComponent,
        SuccessSnackBarComponent,
        ReasonComponent,
        ApproveCancelComponent,
        ProvideDestinationTagComponent,
        RedeemCgiftComponent,
        CheckByProviderComponent,
        BuyCurrencyComponent,
    ],
    exports: [
        ClipboardDirective,
        ClickOutsideDirective,
        ClickElsewhereDirective,
        LineChartComponent,
        BarChartComponent,
        PieChartComponent,
        TableBaseComponent,
        PositionTableComponent,
        ActivePositionsTableComponent,
        SelectFilterComponent,
        AmountFormatterDirective,
        PriceFormatterDirective,
        QuantityFormatterDirective,
        TvChartContainerComponent,
        ResponsiveMenuComponent,
        MultipleSelectComponent,
        NotificationsComponent
    ]
})
export class AppCommonModule {
}
