import {Pipe, PipeTransform} from '@angular/core';
import {Utl} from '../../helpers/utl';

@Pipe({name: 'amount'})
export class AmountPipe implements PipeTransform {


    transform(value: any, currency: string) {
        return Utl.formatAmount(currency, value);
        // if (typeof value === 'string') {
        //     value = parseFloat(value);
        // }
        // if (typeof value !== 'number') {
        //     return '???';
        // }

        // return value.toFixed(precision);
    }
}
