import {EventEmitter, Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';

@Injectable()
export class FirebaseMessagingService {
    public tokenReceivedEmitter: any = new EventEmitter();

    constructor(
        private angularFireMessaging: AngularFireMessaging) {
        this.angularFireMessaging.messaging.subscribe(
            (messaging) => {
                messaging.onMessage = messaging.onMessage.bind(messaging);
                messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
            }
        );
    }

    /**
     * request permission for notification from firebase cloud messaging
     *
     * @param userId userId
     */
    requestPermission(userId) {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                this.tokenReceivedEmitter.emit({status: true, result: token});
            },
            (err) => {
                this.tokenReceivedEmitter.emit({status: false, result: err});
            }
        );
    }
}
