import {Injectable} from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';
import {map} from 'rxjs/operators';
import 'rxjs/add/operator/map';
import * as moment from 'moment';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Utl} from '../helpers/utl';

@Injectable()
export class UserService {
    static emptyUser: any = {apiKeys: ['']};
    private readonly hostUrl: string;
    private currentUserChanged: boolean = true;
    private firstLoad: boolean = true;
    currentUser: BehaviorSubject<any> = null;

    constructor(private networkHelper: NetworkHelper,
                private storage: StorageService) {
        this.hostUrl = environment.host;
    }

    getCurrentUser(): BehaviorSubject<any> {
        if (!this.currentUserChanged) {
            return this.currentUser;
        }

        if (this.firstLoad) {
            this.firstLoad = false;
            this.currentUser = new BehaviorSubject<any>(UserService.emptyUser);
        }

        if (this.currentUserChanged) {
            this.currentUserChanged = false;

            const userId = this.storage.getItem('userId');
            this.get(userId)
                .subscribe((user) => {
                        this.currentUser.next(user.result);
                    },
                    (error) => {
                        this.currentUserChanged = true; // need to get new fresh user if attempt failed
                        this.firstLoad = true; // need to create fresh BehaviorSubject if attempt failed

                        this.currentUser.error(error); // propagate up
                    });
        }

        return this.currentUser;
    }

    userInfo(data): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/user/info`, data);
    }

    getBalance(userId: string, currency: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/user/${userId}/balances`, {currency: currency});
    }

    getBalances(userId: string, includeSummary: boolean = true, includePending: boolean = true): Observable<any> {
        return this.networkHelper
            .get(`${this.hostUrl}/user/${userId}/balances`, {
                includeSummary: includeSummary,
                includePending: includePending
            })
            .pipe(map((result: any) => {
                const balances = result.result;

                const userBalances: any[] = [];
                let baseFiatCurrency = '';
                for (const currency in balances.total) {
                    if (!balances.total.hasOwnProperty(currency)) {
                        continue;
                    }
                    const balance: any = {};
                    balance.total = balances.total[currency];
                    if (includePending) {
                        balance.pending = balances.pending[currency];
                    }
                    balance.available = balances.available[currency];
                    balance.currency = currency;
                    balance.name = balances.currencyDetails[currency].name;
                    balance.iconUrl = balances.currencyDetails[currency].iconUrl;
                    balance.depositActive = balances.currencyDetails[currency].depositActive;
                    balance.withdrawActive = balances.currencyDetails[currency].withdrawActive;
                    balance.isCrypto = balances.currencyDetails[currency].isCrypto;

                    if (includeSummary) {
                        const baseCurrencies = Object.keys(balances.summaries[currency]);
                        const btcIndex = baseCurrencies.indexOf('BTC');
                        if (btcIndex === -1) {
                            continue;
                        }
                        baseCurrencies.splice(btcIndex, 1);
                        if (!baseCurrencies.length) {
                            continue;
                        }
                        baseFiatCurrency = baseCurrencies[0];

                        balance.summary = {
                            BTC: balances.summaries[currency].BTC,
                            [baseFiatCurrency]: balances.summaries[currency][baseFiatCurrency]
                        };
                    }
                    userBalances.push(balance);
                }

                if (includeSummary) {
                    return {wallets: userBalances, summary: balances.summary, baseFiatCurrency: baseFiatCurrency};
                } else {
                    return {wallets: userBalances};
                }
            }));
    }

    getReferralFees(userId: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/user/${userId}/referral-fees`);
    }

    getReferralCount(userId: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/user/${userId}/referral-count`);
    }

    getReferenceToken(userId: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/user/${userId}/reference-token`);
    }

    getReferralsCountByDate(startDate: number, endDate: number, userOrEmail?: string): Observable<any> {
        const data: any = {
            startDate: startDate,
            endDate: endDate
        };

        if (userOrEmail) {
            if (Utl.isValidEmail(userOrEmail)) {
                data.email = userOrEmail;
            } else {
                data.userId = userOrEmail;
            }
        }

        return this.networkHelper.post(`${this.hostUrl}/user/referral-count-by-date`, data);
    }

    getReferralsTradesByLevel(startDate: number, endDate: number, userOrEmail?: string): Observable<any> {
        const data: any = {
            startDate: startDate,
            endDate: endDate
        };

        if (userOrEmail) {
            if (Utl.isValidEmail(userOrEmail)) {
                data.email = userOrEmail;
            } else {
                data.userId = userOrEmail;
            }
        }

        return this.networkHelper.post(`${this.hostUrl}/user/referral-trades-by-level`, data);
    }

    getReferralsTradesPerLevelByDate(startDate: number, endDate: number, userOrEmail?: string): Observable<any> {
        const data: any = {
            startDate: startDate,
            endDate: endDate
        };

        if (userOrEmail) {
            if (Utl.isValidEmail(userOrEmail)) {
                data.email = userOrEmail;
            } else {
                data.userId = userOrEmail;
            }
        }

        return this.networkHelper.post(`${this.hostUrl}/user/referral-trades-by-date`, data);
    }

    getFee(feeType: string, currencyOrPair: string, amount: number,
           isInclusive: boolean, includeParentFees: boolean, paymentProvider?: string): Observable<any> {
        const tmpCurrencyOrPair = currencyOrPair.replace(/\//, '-');
        const query: any = {};
        if (paymentProvider) {
            query.paymentProvider = paymentProvider;
        }

        return this.networkHelper.get(`${this.hostUrl}/user/fee/${feeType}/${tmpCurrencyOrPair}/${amount}/${isInclusive}/${includeParentFees}`, query);
    }

    firstLogin(): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/user/first-login`, {});
    }

    confirmData(data): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/user/kyc`, data);
    }

    get(userId: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/user/${userId}`, {});
    }

    setUserLanguage(lang): Observable<any> {
        return this.networkHelper.put(`${this.hostUrl}/user/language`, {lang: lang});
    }

    getProfile(userId: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/user/profile/${userId}`, {});
    }

    updateUser(user: any): Observable<any> {
        this.currentUserChanged = true;

        return this.networkHelper.put(`${this.hostUrl}/user/${user.id}`, user);
    }

    systemNotificationStatusChange(): Observable<any> {
        this.currentUserChanged = true;
        return this.networkHelper.put(`${this.hostUrl}/notifications/change-subscription-status`, {});
    }

    setPromo(user: any, promoCode): Observable<any> {
        return this.networkHelper.put(`${this.hostUrl}/user/promo/${user.id}`, {promoCode: promoCode});
    }

    getEstimated(base: string, quote: string): Observable<any> {
        return this.networkHelper.getOut(`https://min-api.cryptocompare.com/data/price?fsym=${base}&tsyms=${quote}`);
    }

    getAddress(currency: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/user/deposit/${currency}`, {});
    }

    getNewAddress(currency: string): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/user/deposit/${currency}`, {});
    }

    withdraw(currency, amount, address): Observable<any> {
        const data = {
            currency: currency,
            amount: amount,
            address: address
        };
        return this.networkHelper.post(`${this.hostUrl}/user/withdraw`, data);
    }

    getUserDocument(userId, dim, dir, name): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/user/documents/${userId}?dim=${dim}&dir=${dir}&name=${name}`);
    }

    confirmWithdraw(token): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/user/approve-withdraw/${token}`);
    }

    emailApiKey(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/user/email-api-key`);
    }

    revokeApiKey(index): Observable<any> {
        const data = {
            apiKeyIndex: index
        };
        return this.networkHelper.post(`${this.hostUrl}/user/revoke-api-key`, data);
    }

    prepareDepositFiat(provider, currency, amount): Observable<any> {
        const data = {
            currency: currency,
            amount: amount
        };
        return this.networkHelper.post(`${this.hostUrl}/fiat-payment-system/generate-deposit-data/${provider}`, data);
    }

    prepareWithdrawFiat(provider, currency, amount, address = null): Observable<any> {
        const data = {
            currency: currency,
            amount: amount
        };

        if (address) {
            data['address'] = address;
        }

        return this.networkHelper.post(`${this.hostUrl}/fiat-payment-system/withdraw-fiat/${provider}`, data);
    }

    updateVisits(token): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/user/increment-visits`, {referrerToken: token});
    }

    getAllUserTrades(symbol: string, side: string, page: number, limit: number,
                     startDate: number, endDate: number, userId: string, containTotals: boolean): Observable<any> {
        const data = {
            symbol: symbol,
            side: side,
            page: page,
            itemsPerPage: limit,
            startDate: startDate,
            endDate: endDate,
            containTotals: containTotals
        };

        if (userId) {
            data['userId'] = userId;
        }

        return this.networkHelper.get(`${this.hostUrl}/user/trades`, data);
    }

    getTradingSummaryPerSymbol(symbol: string, startDate: number, endDate: number, userId: string): Observable<any> {
        const data = {
            symbol: symbol,
            startDate: startDate,
            endDate: endDate,
            userId: userId
        };

        return this.networkHelper.get(`${this.hostUrl}/user/trades-summary`, data);
    }

    getAllUserTradesCsv(symbol, side, startDate, endDate, userId?): Observable<any> {
        const tzShift = moment().utcOffset();
        const query: any = {
            symbol: symbol,
            side: side,
            startDate: startDate,
            endDate: endDate,
            timeZoneShift: tzShift
        };
        if (userId) {
            query.userId = userId;
        }

        return this.networkHelper.get(`${this.hostUrl}/user/csv/trades`, query);
    }

    updateUserFavorites(pairs): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/user/favorite-pairs`, {pairs: pairs});
    }

    cancelWithdrawal(withdrawalId: string): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/cancel-withdrawal`, {withdrawalId: withdrawalId});
    }

    removeReferral(userId: string, referrerUserId: string): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/user/remove-referral`, {userId: userId, referrerUserId: referrerUserId});
    }

    redeemGiftCard(currency: string, cardNumber: string, cardPin: string): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/user/giftcard`, {
            currency: currency,
            cardNumber: cardNumber,
            cardPin: cardPin
        });
    }

    getAvailableAirdrops(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/airdrops`);
    }

    receiveAirdrop(currency: string): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/airdrops/${currency}`, {});
    }

    markForKYCCheck(userId: string, tiersToCheck: number[]) {
        return this.networkHelper.post(`${this.hostUrl}/mark-for-kyc-check`, {
            userId: userId,
            tiersToCheck: tiersToCheck
        });
    }

    updateNotificationStatus(on: boolean, token?: String) {
        const data: any = {
            on: on
        };

        if (on) {
            data.token = token;
        }

        return this.networkHelper.post(`${this.hostUrl}/notifications/updateStatus`, data);
    }

    getkycAccessToken(userId: string, kycStage: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/user/kyc-access-token/${userId}/${kycStage}`);
    }
}
