import {Component, OnInit} from '@angular/core';
import {MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../services/user.service';
import {CurrencyService} from '../../services/currency.service';

@Component({
    selector: 'app-redeem-cgift-component',
    templateUrl: 'redeem-cgift.template.html'
})
export class RedeemCgiftComponent implements OnInit {
    cardNumber: string;
    pinCode: string;
    currencies: any = [];
    selectedCurrency: string = 'BTC';

    constructor(public dialogRef: MatDialogRef<RedeemCgiftComponent>,
                public translate: TranslateService,
                private snackBar: MatSnackBar,
                private userService: UserService,
                private currencyService: CurrencyService) {

    }

    ngOnInit() {
        this.currencyService.getCurrencies()
            .subscribe((result) => {
                this.currencies = result;
            }, (err) => {
                this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
            });
    }

    redeem() {
        this.userService.redeemGiftCard(this.selectedCurrency, this.cardNumber, this.pinCode)
            .subscribe((result) => {
                this.dialogRef.close({result: true});
            }, (err) => {
                this.snackBar.open(this.translate.instant('EXCHANGE.WALLETS.REDEEM_INVALID'),
                    this.translate.instant('CLOSE'), {duration: 3000});
            });
    }

    close() {
        this.dialogRef.close();
    }
}

