<div class="dialog-container fx-all-100 layout-all-col-nowrap">

    <div class="content-box fx-all-100 layout-all-row-wrap">
        <div class="fx-all-100 layout-all-row-nowrap">
            <div class="fx-all-100">
                <mat-form-field class="kyc-reject-reason">
                    <mat-select [formControl]="reasonCtrl"
                                placeholder='{{"EXCHANGE.ADMIN.USER_PAGE.REJECT_REASON" | translate}}' #reasonSelect>
                        <mat-option>
                            <ngx-mat-select-search
                                placeholderLabel='{{"EXCHANGE.ADMIN.USER_PAGE.TYPE_REASON" | translate}}'
                                [formControl]="reasonFilterCtrl"></ngx-mat-select-search>
                        </mat-option>

                        <mat-option *ngFor="let reason of filteredReasons | async" [value]="reason">
                            {{reason.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="actions-box fx-all-100 layout-all-row-nowrap align-all-fxEnd-fxStr">
        <div class="fx fill-0-1">
            <button mat-raised-button class="approve-button-filled shadowed tall-button" (click)="confirm()">
                {{"EXCHANGE.MODALS.CONFIRM" | translate}}
            </button>
        </div>
        <div class="fx fill-0-1 offset-all-1">
            <button mat-raised-button class="reject-button-filled shadowed tall-button" (click)="cancel()">
                {{"EXCHANGE.MODALS.CANCEL" | translate}}
            </button>
        </div>
    </div>

</div>
