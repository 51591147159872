<app-maintenance [userRole]="role"></app-maintenance>
<header [class.user_header]="isAuthenticated">
    <div class="header-container container-fluid">
        <div class="header-menu-container">

            <div class="left-menu-container">
                <app-user-header *ngIf="role === 'user' || !isAuthenticated"
                                 [isAuthenticated]="isAuthenticated"></app-user-header>
                <app-admin-header *ngIf="isAuthenticated && (role === 'admin' || role === 'support-admin' || role === 'accountant')"></app-admin-header>
                <app-kyc-admin-header *ngIf="isAuthenticated && role === 'admin-kyc'"></app-kyc-admin-header>
            </div>

            <div class="right-menu">
                <div class="user-panel">
                    <app-notifications *ngIf="systemNotificationsEnabled"></app-notifications>

                    <div *ngIf="!isAuthenticated"
                         class="navbar-collapse collapse navbar-right hamburger-menu visible-sm-and-lg profile-bar">
                        <ul class="nav navbar-nav">
                            <li class="dropdown languages">
                                <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button"
                                   aria-expanded="false">
                                    <i class="material-icons">menu</i>
                                </a>

                                <ul class="dropdown-menu" role="menu">
                                    <li><a class="language" [routerLink]="['/sessions/signin']"
                                           routerLinkActive="active">{{"EXCHANGE.SESSIONS.SIGN_IN" | translate}}</a>
                                    </li>
                                    <li><a class="language" [routerLink]="['/sessions/signup']"
                                           routerLinkActive="active">{{"EXCHANGE.SESSIONS.SIGN_UP" | translate}}</a>
                                    </li>
                                </ul>
                            </li>

                        </ul>
                    </div>

                    <div *ngIf="isAuthenticated"
                         class="navbar-right hamburger-menu profile-bar">

                                <!--<app-user-header *ngIf="role === 'user' || !isAuthenticated"-->
                                                 <!--[isAuthenticated]="isAuthenticated" [isMobile]="true"></app-user-header>-->
                                <app-admin-header
                                    *ngIf="isAuthenticated && (role === 'admin' || role === 'support-admin' || role === 'accountant')"
                                    [isMobile]="true">
                                </app-admin-header>
                    </div>

                    <div *ngIf="!isAuthenticated || role === 'user'"
                         class="new-design-btn-wrapper">
                        <a mat-button mat-raised-button
                           class="button-new-design button-main-shadow"
                           href="https://new.bitblinx.com/"
                           target="_blank">
                            {{'EXCHANGE.USER.NEW_DESIGN' | translate}}
                        </a>
                    </div>

                    <div class="theme-bar navbar-right profile-bar">
                        <button class="theme-icon {{theme.name}}"
                                [ngClass]="{'active': theme.name === currentTheme.name}"
                                *ngFor="let theme of themes" (click)="selectTheme(theme)"
                                [disabled]="!canChangeTheme">
                            <i class="material-icons">{{theme.icon}}</i>
                        </button>
                    </div>

                    <div id="language_bar" class="navbar-right profile-bar">
                        <ul class="nav navbar-nav">
                            <li class="dropdown languages">
                                <a href="#" class="dropdown-toggle language-flag-toggle" data-toggle="dropdown"
                                   role="button"
                                   aria-expanded="false">
                                    <span class="lang-flag-wrapper">
                                    <img id="imgLangSel"
                                         src="{{langs[selectedLang].img}}"
                                         class="img-thumbnail icon-small">
                                    </span> <span id="lanNavSel">{{langs[selectedLang].name}}</span>
                                    <span class="caret"></span></a>
                                <ul class="dropdown-menu" role="menu">

                                    <li *ngFor="let lang of availableLangs">
                                        <a id="{{lang.id}}" *ngIf="selectedLang !== lang.slug" (click)="setLang(lang.slug)"
                                           class="language">
                                            <img id="img{{lang.id}}" src="{{lang.img}}" class="img-thumbnail icon-small">  
                                            <span id="lan{{lang.id}}">{{lang.name}}</span>
                                        </a>
                                    </li>

                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div *ngIf="isAuthenticated" class="navbar-right profile-bar">
                        <ul class="nav navbar-nav">
                            <li class="dropdown languages">
                                <a href="#" class="dropdown-toggle user-profile-toggle" data-toggle="dropdown"
                                   role="button"
                                   aria-expanded="false">
                                    <span id="imgProfileSel" class="img-thumbnail icon-small profile-icon"></span>
                                    <span [routerLink]="['/user/settings']" *ngIf="role === 'user' && verified === 'unverified'"
                                          matTooltip='{{"EXCHANGE.USER.UNVERIFIED" | translate}}'
                                          class="verification-notification">1</span>
                                    <span *ngIf="role === 'user' && verified === 'submitted'"
                                          matTooltip='{{"EXCHANGE.USER.SUBMITTED" | translate}}'
                                          class="verification-notification submitted">1</span>
                                    <span id="name">{{firstName}}</span>
                                    <span class="caret"></span>
                                </a>
                                <ul class="dropdown-menu profile-menu" role="menu">
                                    <li>
                                        <a [routerLink]="['/user/settings']" class="language">
                                            <span>{{'EXCHANGE.USER.PROFILE' | translate}}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="language" style="cursor: pointer;" (click)="signOut()">
                                            <span>{{'EXCHANGE.USER.SIGN_OUT' | translate}}</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <!-- <div class="user-panel" *ngIf="!isAuthenticated">
                        <div class="item login-layout"><a [routerLink]="['/sessions/signin']" routerLinkActive="active">{{"EXCHANGE.SESSIONS.SIGN_IN"
                            | translate}}</a></div>
                        <div class="item login-layout"><a [routerLink]="['/sessions/signup']" routerLinkActive="active">{{"EXCHANGE.SESSIONS.SIGN_UP"
                            | translate}}</a></div>
                    </div> -->

                </div>
            </div>
            <div class="clear-fix"></div>
        </div>
    </div>
</header>

<div class="wrapper" [class.user-body]="isAuthenticated">
    <router-outlet></router-outlet>
</div>

<footer>
    <div class="footer-container fx-all-100 layout-all-row-wrap align-all-spcBtw-ctr align-ltMd-ctr-ctr">
        <div class="footer-left-side flex fill-all-0-1 flex-ltMd-100 layout-all-row-nowrap align-all-fxStr-ctr">
            <div class="logo-wrapper flex">
                <div class="logo"></div>
            </div>
            <div class="copyright-box fx align-all-fxStr-ctr"
                 [innerHTML]="'EXCHANGE.APP.ALL_RIGHTS_RESERVED' | translate:{year: currentYear} | safeHtml">
            </div>
        </div>
        <div class="footer-menu flex fill-1-1 flex-ltMd-100 layout-all-row-nowrap align-all-fxEnd-ctr">
            <div class="footer-menu-item">
                <a class="footer-link" href="https://bitblinx.com/contact-us" target="_blank">
                    {{ 'EXCHANGE.APP.CONTACT_US' | translate }}
                </a>
            </div>

            <div class="footer-menu-item">
                <a class="footer-link" href="https://bitblinx.com/terms-of-use" target="_blank">
                    {{ 'EXCHANGE.APP.TERMS_OF_USE' | translate }}
                </a>
            </div>
            <div class="footer-menu-item">
                <a class="footer-link" href="https://bitblinx.com/privacy-policy" target="_blank">
                    {{ 'EXCHANGE.APP.PRIVACY_POLICY' | translate }}
                </a>
            </div>
            <div class="footer-menu-item">
                <a class="footer-link" href="https://bitblinx.freshdesk.com/support/home" target="_blank">
                    {{ 'EXCHANGE.APP.SUPPORT' | translate }}
                </a>
            </div>
        </div>
    </div>
</footer>
