import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';

@Pipe({name: 'timeAgo'})
export class TimeAgoPipe implements PipeTransform {
    result: string = '';

    constructor(private translate: TranslateService) {}

    transform(date: any) {
        let result = '';
        const minute = 60;
        const hour = 3600;
        const day = 86400;
        const week = 604800;
        const diff = moment().diff(date, 'seconds');

        if (diff < minute) {
            result = this.translate.instant('TIME_AGO.MOMENT_AGO');
        } else if (diff >= minute && diff < minute + 59) {
            result = this.translate.instant('TIME_AGO.MINUTE_AGO');
        } else if (diff >= minute + 59 && diff < hour) {
            result = this.translate.instant('TIME_AGO.MINUTES_AGO', {minutes: Math.floor(diff / minute)});
        } else if (diff >= hour && diff < hour + 60 * minute) {
            result = this.translate.instant('TIME_AGO.HOUR_AGO');
        } else if (diff >= hour + 60 * minute && diff < day) {
            result = this.translate.instant('TIME_AGO.HOURS_AGO', {hours: Math.floor(diff / hour)});
        } else if (diff >= day && diff < day + 24 * hour) {
            result = this.translate.instant('TIME_AGO.DAY_AGO');
        } else if (diff >= day + 24 * hour && diff < week) {
            result = this.translate.instant('TIME_AGO.DAYS_AGO', {days: Math.floor(diff / day)});
        } else if (diff >= week && diff < week + 7 * day) {
            result = this.translate.instant('TIME_AGO.WEEK_AGO');
        } else if (diff >= week + 7 * day) {
            result = this.translate.instant('TIME_AGO.WEEKS_AGO', {weeks: Math.floor(diff / week)});
        }
        return result;
    }
}
