<div class="dialog-container fx-all-100 layout-all-col-nowrap">
    <div class="header-box flex layout-all-row-wrap align-all-spcBtw-ctr">
        <div class="header-text fx-all-100">
            <p>{{'EXCHANGE.ADMIN.USER_PAGE.VERIFY_BY_PROVIDER' | translate}}</p>
        </div>
    </div>
    <div class="content-box fx-all-100 layout-all-row-wrap">
        <div class="fx-all-100 layout-all-row-nowrap align-all-fxEnd-fxStr">
            <app-multiple-select
                class="fx-all-100"
                [options]="tiers"
                [selects]="selectTiers"
                [namePath]="'EXCHANGE.ADMIN.FILTER.TIER_'"
                [placeholder]="'EXCHANGE.ADMIN.USER_PAGE.TIER_TO_CHECK'"
                [classnames]="'mat-form-field ink-blue-form-field fx-all-100'"
                (output)="onTiersChanged($event)">
            </app-multiple-select>
        </div>
    </div>
    <div class="actions-box fx-all-100 layout-all-row-nowrap align-all-fxEnd-fxStr">
        <div class="fx fill-0-1">
            <button mat-raised-button class="approve-button-filled shadowed tall-button" (click)="confirm()">
                {{"EXCHANGE.MODALS.CONFIRM" | translate}}
            </button>
        </div>
        <div class="fx fill-0-1 offset-all-4">
            <button mat-raised-button class="reject-button-filled shadowed tall-button" (click)="cancel()">
                {{"EXCHANGE.MODALS.CANCEL" | translate}}
            </button>
        </div>
    </div>
</div>
