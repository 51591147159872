import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NetworkHelper} from '../helpers/network.helper';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class ChartService {
    public hostUrl: string;

    constructor(private router: Router, private networkHelper: NetworkHelper) {
        this.hostUrl = environment.host;
    }

    getChart(symbol = null, period = 'M15', limit = null): Observable<any> {
        const data = {
            symbol: symbol,
            period: period,
        };

        if (limit) {
            data['limit'] = limit;
        }

        return this.networkHelper.get(`${this.hostUrl}/chart`, data);
    }
}
