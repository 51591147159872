import { Component, OnInit } from '@angular/core';
import {MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-provide-destination-tag',
  templateUrl: './provide-destination-tag.component.html'
})
export class ProvideDestinationTagComponent implements OnInit {

    reasonText = '';

    constructor(public dialogRef: MatDialogRef<ProvideDestinationTagComponent>,
                public snackBar: MatSnackBar,
                public translate: TranslateService) {
    }

    ngOnInit() {
    }

    cancel() {
        this.dialogRef.close({result: false});
    }

    confirm() {
        this.dialogRef.close({result: true});
    }

}
