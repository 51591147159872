import {Injectable} from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';
import * as moment from 'moment';

@Injectable()
export class MarginService {
    private readonly hostUrl: string;

    constructor(private networkHelper: NetworkHelper, private storage: StorageService) {
        this.hostUrl = environment.host;
    }

    getPositionHistoryByUserId(page: number, itemsPerPage: number, userId: string, symbol: string, type: string,
                               startDate?: number, endDate?: number): Observable<any> {
        const data: any = {
            page: page,
            itemsPerPage: itemsPerPage,
            userId: userId,
            type: type,
            symbol: symbol
        };

        if (startDate) {
            data.startDate = startDate;
        }
        if (endDate) {
            data.endDate = endDate;
        }
        return this.networkHelper.get(`${this.hostUrl}/position/history`, data);
    }

    getPositionHistoryByUserEmail(page: number, itemsPerPage: number, symbol: string, type: string,
                                  startDate: number, endDate: number, email: string): Observable<any> {
        const data = {
            page: page,
            itemsPerPage: itemsPerPage,
            email: email,
            type: type,
            startDate: startDate,
            endDate: endDate,
            symbol: symbol
        };
        return this.networkHelper.get(`${this.hostUrl}/position/history`, data);
    }

    getPositionHistoryCsv(userId: string, symbol: string, type: string,
                          startDate: number, endDate: number, email?: string): Observable<any> {
        const tzShift = moment().utcOffset();

        const data: any = {
            userId: userId,
            symbol: symbol,
            type: type,
            startDate: startDate,
            endDate: endDate,
            timeZoneShift: tzShift
        };

        if (email) {
            data.email = email;
        }

        return this.networkHelper.get(`${this.hostUrl}/position/csv/history`, data);
    }

    openPosition(symbol, type, quantity, leverage, stopLossPrice): Observable<any> {
        const data = {
            symbol: symbol,
            type: type,
            quantity: quantity,
            leverage: leverage,
            stopLossPrice: stopLossPrice
        };

        return this.networkHelper.post(`${this.hostUrl}/position`, data);
    }

    getMarginConfigs(symbol): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/position/margin-configs/${symbol}`, {});
    }

    getPositionById(id): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/position/${id}`, {});
    }

    closePosition(id): Observable<any> {
        return this.networkHelper.delete(`${this.hostUrl}/position/${id}`, {});
    }

    getActivePositionsByUserId(page, itemsPerPage, userId, symbol, type): Observable<any> {
        const data = {
          page: page,
          itemsPerPage: itemsPerPage,
          userId: userId,
          symbol: symbol,
          type: type
        };
        return this.networkHelper.get(`${this.hostUrl}/position/active`, data);
    }

    getActivePositionsByUserEmail(page, itemsPerPage, email, symbol, type): Observable<any> {
        const data = {
            page: page,
            itemsPerPage: itemsPerPage,
            email: email,
            symbol: symbol,
            type: type
        };
        return this.networkHelper.get(`${this.hostUrl}/position/active`, data);
    }

    getActivePositionsCsv(userId: string, symbol: string, type: string, email?: string): Observable<any> {
        const tzShift = moment().utcOffset();

        const data: any = {
            userId: userId,
            symbol: symbol,
            type: type,
            timeZoneShift: tzShift
        };
        if (email) {
            data.email = email;
        }
        return this.networkHelper.get(`${this.hostUrl}/position/csv/active`, data);
    }

    getRealized(userId, symbol, type, startDate, endDate): Observable<any> {
        const data = {
            userId: userId,
            symbol: symbol,
            type: type,
            startDate: startDate,
            endDate: endDate
        };

        return this.networkHelper.get(`${this.hostUrl}/position/realized-pl`, data);
    }

    getPositionReport(symbol: string, startDate: number, endDate: number, includeCurrent: boolean): Observable<any> {
        const data = {
            symbol: symbol,
            startDate: startDate,
            endDate: endDate,
            includeCurrent: includeCurrent
        };
        return this.networkHelper.get(`${this.hostUrl}/admin/position-report`, data);
    }
}
