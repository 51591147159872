import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {OrderService} from '../../services/order.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Utl} from '../../helpers/utl';
import {TranslateService} from '@ngx-translate/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-buy-currency',
  templateUrl: './buy-currency.component.html',
  styleUrls: ['./buy-currency.component.css']
})
export class BuyCurrencyComponent implements OnInit, OnDestroy {

    wallet: any = null;
    amount: number;
    amountControl: FormControl = new FormControl();
    totalControl: FormControl = new FormControl();
    amountSubscription: Subscription;
    totalSubscription: Subscription;
    total: any;
    rate: number = 0;
    isTotalReadonly: boolean = false;
    isBuyDisabled: boolean = true;

    constructor(public dialogRef: MatDialogRef<BuyCurrencyComponent>,
                private orderService: OrderService,
                public snackBar: MatSnackBar,
                public translate: TranslateService) { }

    ngOnInit(): void {
        this.subscribeAmountForm();
        this.subscribeTotalForm();
        this.getDirectTradePrice();
    }

    ngOnDestroy(): void {
        this.amountSubscription.unsubscribe();
        this.totalSubscription.unsubscribe();
    }


    subscribeAmountForm(): void {
        this.amountSubscription = this.amountControl.valueChanges
            .debounceTime(200)
            .subscribe((amount) => {
                if (amount && Utl.isValidNumber(amount)) {
                    this.totalControl.setValue(Utl.formatQuantity(`${this.wallet.currency}/EUR`, this.amount * this.rate), {onlySelf: true, emitEvent: false});
                    this.isBuyDisabled = false;
                } else {
                    this.totalControl.setValue(0, {onlySelf: true, emitEvent: false});
                    this.total = 0;
                    this.isBuyDisabled = true;
                }
            });
    }


    subscribeTotalForm(): void {
        this.totalSubscription = this.totalControl.valueChanges
            .debounceTime(200)
            .subscribe((totalAmount) => {
                if (totalAmount && Utl.isValidNumber(totalAmount)) {
                    this.amountControl.setValue(Utl.formatQuantity(`${this.wallet.currency}/EUR`, totalAmount / this.rate), {onlySelf: true, emitEvent: false});
                    this.isBuyDisabled = false;
                } else {
                    this.amountControl.setValue(0, {onlySelf: true, emitEvent: false});                    
                    this.isBuyDisabled = true;
                }
            });
    }    

    getDirectTradePrice(): void {
        this.orderService.getDirectTradePrice(`${this.wallet.currency}/EUR`)
            .subscribe((result) => {
                this.rate = result.result;
            }, (err) => {
                this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
            });
    }

    onBuy(): void {
        if (!this.total) {
            this.snackBar.open(
                this.translate.instant('INVALID_PRICE'),
                this.translate.instant('CLOSE'), {duration: 3000});
            return;
        }

        this.orderService.directTrade(this.total, this.wallet.currency, 'EUR')
            .subscribe((result) => {
                this.amount = null;
                this.total = null;
                const orderInfo = result.result;

                this.snackBar.open(
                    `Status: ${orderInfo.status} 
                              Amount: ${Utl.formatAmount(this.wallet.currency, orderInfo.quantity)} 
                              Symbol: ${orderInfo.symbol}
                              ${Number(orderInfo.averagePrice) ? 'Price: ' + this.rate : ''}`,
                    this.translate.instant('CLOSE'), {duration: 3000});
                this.dialogRef.close();

            }, (err) => {
                this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
            });
    }

    close() {
        this.dialogRef.close();
    }


}
