import {Inject, Injectable, NgZone} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {LoggerService} from './logger.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {StorageService} from './storage.service';
import {UserService} from './user.service';

interface MerchantAction {
    doWork(params: any, ptr: (data: any) => void): void;
}

class GetDepositAddressAction implements MerchantAction {
    constructor(private userService: UserService) {

    }

    doWork(params: any, ptr: (data: any) => void): void {
        this.userService.getAddress(params.currency).subscribe((result) => {
            return ptr(result.result);
        });
    }
}


@Injectable()
export class VoucherManager {
    public static vendorActions: string[] = ['GET_DEPOSIT_ADDRESS'];

    env: any = environment;
    vendorId: string;
    vendorURL: string;
    vendorAction: string;


    constructor(@Inject(DOCUMENT) private document: Document,
                private http: HttpClient,
                private logger: LoggerService,
                private router: Router,
                private zone: NgZone,
                private route: ActivatedRoute,
                private userService: UserService,
                private storage: StorageService) {
    }

    public init() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Navigation Ended Successfully.
                if (localStorage['vendorAction'] &&
                    localStorage.getItem('vendorId') &&
                    localStorage.getItem('vendorURL') &&
                    this.storage.getItem('isLoggedin') === 'true') {

                    const email = this.storage.getItem('email');
                    const lang = localStorage.getItem('lang');

                    switch (localStorage.getItem('vendorAction')) {
                        case 'GET_DEPOSIT_ADDRESS': {
                            const action = new GetDepositAddressAction(this.userService);
                            const vendorParams = JSON.parse(localStorage.getItem('vendorParams'));
                            const vendorURL = localStorage.getItem('vendorURL');
                            action.doWork(vendorParams, (result) => {
                                let path;
                                if (vendorURL.includes('?')) {
                                     path = `${vendorURL}&`;
                                } else {
                                    path = `${vendorURL}?`;
                                }

                                path += `address=${result.address}&` +
                                    `currency=${vendorParams.currency}&` +
                                    `email=${email}&` +
                                    `lang=${lang}`;

                                this.document.location.href = path;
                            });
                            break;
                        }
                        default:
                            return;
                    }

                    localStorage.removeItem('vendorParams');
                    localStorage.removeItem('vendorAction');
                    localStorage.removeItem('vendorId');
                    localStorage.removeItem('vendorURL');
                }
            }
        });
    }
}
