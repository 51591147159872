import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NetworkHelper} from '../helpers/network.helper';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class SettingsService {
    readonly hostUrl: string;

    constructor(private router: Router, private networkHelper: NetworkHelper) {
        this.hostUrl = environment.host;
    }

    getSettings(key = null): Observable<any> {
        const data = {};
        if (key) {
            data['key'] = key;
        }

        return this.networkHelper.post(`${this.hostUrl}/settings`, data);
    }

    setSetting(key, value): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/settings/edit`, {key: key, value: value});
    }
}
