export interface Reason {
    id: number;
    name: string;
}

// Dont add any reason with id: 0 to this list , id: 0  is reserved for custom reasons
export const REASONS: Reason[] = [
    {
        name: 'Selfie with a document is missing or is not complete',      
        id: 1
    },
    {
        // tslint:disable-next-line:max-line-length
        name: 'Text of the document in the selfie is not fully or clearly visible',
        id: 2
    },
    {
        name: 'Account is not verified',
        id: 3
    },
    {
        name: 'Bad quality of the selfie',
        id: 4
    },
    {
        name: 'Bad quality of the document photo or/and the document is not the original copy',
        id: 5
    },
    {
        name: 'Document to verify the identity is missing\n' +
            'Please add a photo/scan of your passport Bio page or of the front and back pages of your ID document.',
        id: 6
    },
    {
        name: 'Document to verify the identity and POA are missing\n' +
            'Please add a photo/scan of your passport Bio page or of the front and back pages of your ID document.\n' +
            'As a proof of address add a utility bill, land line phone bill or bank statement.',
        id: 7
    },
    {
        name: 'POA is missing\n' +
            'Please add a utility bill, land line phone bill or bank statemen',
        id: 8
    },
    {
        name: 'The proof of address doesn\'t qualify as a compliant',
        id: 9
    },
    {
        name: 'Unfortunately BitBlinx is unable to provide services for U.S. citizens',
        id: 10
    },
    {
        name: 'All fields must be filled in Latin letters only',
        id: 11
    }
];
