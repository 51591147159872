import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NetworkHelper} from '../helpers/network.helper';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {StorageService} from './storage.service';

@Injectable()
export class NotificationService {
    private readonly hostUrl: string;

    constructor(private router: Router, private networkHelper: NetworkHelper, private storage: StorageService) {
        this.hostUrl = environment.host;
    }

    getAll(page: number, itemsPerPage: number): Observable<any> {
        const params = {
            page: page,
            itemsPerPage: itemsPerPage
        };

        return this.networkHelper.post(`${this.hostUrl}/notifications/all`, params);
    }

    changeReadStatus(notificationIds: string[], read: boolean): Observable<any> {
        const params: any = {
            read: read,
            notificationIds: notificationIds
        };

        return this.networkHelper.post(`${this.hostUrl}/notifications/mark-as-read`, params);
    }

    changeSeenStatus(notificationIds?: string[]): Observable<any> {
        const params: any = {};
        if (notificationIds) {
            params.notificationIds = notificationIds;
        }
        return this.networkHelper.post(`${this.hostUrl}/notifications/mark-as-seen`, params);
    }

    markAsRemoved(notificationId: string): Observable<any> {
        const params: any = {
            notificationId: notificationId
        };
        return this.networkHelper.post(`${this.hostUrl}/notifications/mark-as-removed`, params);
    }
}
