import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';

export class FileWrapper {
    private static _indexCounter: number = 0;

    public file: File;
    public processed: boolean = false;
    public index: number;

    constructor(file: File) {
        this.index = ++FileWrapper._indexCounter;
        this.file = file;
        this.processed = false;
    }
}

@Injectable()
export class UploadService {
    env: any = environment;

    constructor(private http: HttpClient) {
    }

    public upload(uploadType: string, files: FileWrapper[],
                  callback: (result: {responseBody: any, fileWrapper: FileWrapper}) => void): { [key: string]: Observable<number> } {
        // this will be the our resulting map
        const status = {};

        for (const fw of files) {
            if (fw.processed) {
                continue;
            }

            // create a new multipart-form for every file
            const formData: FormData = new FormData();
            formData.append('file', fw.file, fw.file.name);

            // create a http-post request and pass the form
            // tell it to report the upload progress

            const req = new HttpRequest('POST', `${this.env.host}/uploader/${uploadType}`, formData, {
                reportProgress: true
            });

            // create a new progress-subject for every file
            const progress = new Subject<number>();

            // send the http-request and subscribe for progress-updates
            this.http.request(req).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {

                    // calculate the progress percentage
                    const percentDone = Math.round(100 * event.loaded / event.total);

                    // pass the percentage into the progress-stream
                    progress.next(percentDone);
                } else if (event instanceof HttpResponse) {
                    callback({responseBody: event.body, fileWrapper: fw});

                    // Close the progress-stream if we get an answer form the API
                    // The upload is complete
                    progress.complete();
                }
            });

            // Save every progress-observable in a map of all observables
            status[fw.file.name] = {
                progress: progress.asObservable()
            };
        }

        // return the map of progress.observables
        return status;
    }
}
