import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NetworkHelper} from '../helpers/network.helper';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';
import * as moment from 'moment';
import {StorageService} from './storage.service';

@Injectable()
export class OrderService {
    private readonly hostUrl: string;

    constructor(private router: Router, private networkHelper: NetworkHelper, private storage: StorageService) {
        this.hostUrl = environment.host;
    }

    getAllOpen(page: number, itemsPerPage: number, symbol: string, side: string, userId?: string): Observable<any> {
        const params: any = {
            symbol: symbol,
            side: side,
            page: page,
            itemsPerPage: itemsPerPage
        };

        if (userId) {
            params.userId = userId;
        }

        return this.networkHelper.get(`${this.hostUrl}/open-orders`, params);
    }

    deleteOrder(orderId): Observable<any> {
        return this.networkHelper.delete(`${this.hostUrl}/orders/${orderId}`, {});
    }

    deleteOrderBulk(symbol = null, userId = null) {
        const data = {
            symbol: symbol,
            userId: userId
        };

        return this.networkHelper.delete(`${this.hostUrl}/orders`, data);
    }

    createOrder(data): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/orders`, data);
    }

    getOrdersHistory(symbol: string, side: string, type: string,
                     page: number, itemsPerPage: number, startDate: number, endDate: number,
                     hideCancel: boolean, cancelReason: string, userId?: string, orderID?: string): Observable<any> {
        const query: any = {
            symbol: symbol,
            side: side,
            type: type,
            page: page,
            itemsPerPage: itemsPerPage,
            startDate: startDate,
            endDate: endDate,
            hideCanceled: hideCancel,
            cancelReason: cancelReason
        };

        if (userId) {
            query.userId = userId;
        }

        if (orderID) {
            query.orderID = orderID;
        }

        return this.networkHelper.get(`${this.hostUrl}/orders/history`, query);
    }

    getOrdersHistoryCsv(symbol: string, side: string, type: string, startDate: number, endDate: number,
                        hideCancel: boolean, cancelReason: string, userId?: string, orderID?: string): Observable<any> {
        const tzShift = moment().utcOffset();
        const query: any = {
            symbol: symbol,
            side: side,
            type: type,
            startDate: startDate,
            endDate: endDate,
            hideCanceled: hideCancel,
            cancelReason: cancelReason,
            timeZoneShift: tzShift
        };

        if (userId) {
            query.userId = userId;
        }

        if (orderID) {
            query.orderID = orderID;
        }

        return this.networkHelper.get(`${this.hostUrl}/orders/csv/history`, query);
    }

    getTradesHistory(symbol, side, page, limit, startDate, endDate, containTotals, email = null): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/admin/trades-all`, {
            symbol: symbol,
            side: side,
            page: page,
            itemsPerPage: limit,
            startDate: startDate,
            endDate: endDate,
            containTotals: containTotals,
            email: email ? email : 'any'
        });
    }

    getTradesHistoryCsv(symbol, side, startDate, endDate, email = null): Observable<any> {
        const tzShift = moment().utcOffset();
        return this.networkHelper.get(`${this.hostUrl}/admin/csv/trades-all`, {
            symbol: symbol,
            side: side,
            startDate: startDate,
            endDate: endDate,
            email: email ? email : 'any',
            timeZoneShift: tzShift
        });
    }

    checkForUpdate(lastUpdateTime, symbol): Observable<any> {
        // this method is called by timesr, so we have session check here
        if (this.storage.getItem('isLoggedin') !== 'true') {
            return;
        }
        return this.networkHelper.get(`${this.hostUrl}/orders/update-check`, {
            lastUpdateTime: lastUpdateTime,
            symbol: symbol
        });
    }

    getDirectTradePrice(symbol: string): Observable<any> {
        const params = {
            symbol
        };

        return this.networkHelper.get(`${this.hostUrl}/orders/direct-trade/prices`, params);
    }

    directTrade(quantity: number, baseCurrency: string, quoteCurrency: string): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/orders/direct-trade`, {
            quantity,
            baseCurrency,
            quoteCurrency,
        });
    }
}
