import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material';

@Component({
    selector: 'app-error-snack-bar',
    templateUrl: 'error-snackbar.component.html',
})
export class ErrorSnackBarComponent {
    errorText: string = '';

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string, public snackBarRef: MatSnackBar) {
        this.errorText = data.toString();
        this.errorText = this.errorText.replace(/Error: /i, '');
    }

    close() {
        this.snackBarRef.dismiss();
    }
}
