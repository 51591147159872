import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

class Themes {
    newTheme: string;
    oldTheme: string;
}

@Injectable()
export class ThemeService {
    theme: BehaviorSubject<any> = new BehaviorSubject({});

    constructor() {

    }

    setTheme(themes: Themes) {
        this.theme.next(themes);
    }

    getTheme(): BehaviorSubject<any> {
        return this.theme;
    }
}
