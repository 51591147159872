<div class="big_bg sessions-main-container flex layout-all-row-nowrap">

    <div class="container-fluid flex layout-all-row-nowrap align-all-ctr-ctr">
        <div class="session-form">
            <div class="form-header">
                <a class="head-logo" [routerLink]="['/']">
                    <img class="head-logo-image" alt="">
                </a>
                <span>{{"EXCHANGE.SESSIONS.WITHDRAW_ACTIVATE_TITLE" | translate}}</span>
            </div>
            <div class="order-info">
                <div class="not-found">
                    <div *ngIf="type === 'accepted'">
                        <div class="order-info-first-box">
                            {{"EXCHANGE.SESSIONS.WITHDRAW_ACCEPTED_TITLE" | translate}}
                        </div>
                        <div class="order-info-second-box">
                            {{"EXCHANGE.SESSIONS.WITHDRAW_ACCEPTED" | translate}}
                        </div>
                    </div>

                    <div *ngIf="type === 'processed'">
                        <div class="order-info-first-box">
                            {{"EXCHANGE.SESSIONS.WITHDRAW_PROCESSED_TITLE" | translate}}
                        </div>
                        <div class="order-info-second-box">
                            {{"EXCHANGE.SESSIONS.WITHDRAW_PROCESSED" | translate}}
                        </div>
                    </div>

                    <div *ngIf="type === 'failed'">
                        <div class="order-info-first-box">
                            {{"EXCHANGE.SESSIONS.WITHDRAW_FAILED_TITLE" | translate}}
                        </div>
                        <div class="order-info-second-box">
                            {{"EXCHANGE.SESSIONS.WITHDRAW_FAILED" | translate}}
                        </div>
                    </div>

                    <div *ngIf="type === 'cancelled'">
                        <div class="order-info-first-box">
                            {{"EXCHANGE.SESSIONS.WITHDRAW_CANCELED_TITLE" | translate}}
                        </div>
                        <div class="order-info-second-box">
                            {{"EXCHANGE.SESSIONS.WITHDRAW_CANCELED" | translate}}
                        </div>
                    </div>

                </div>
                <div class="fx-all-100 layout-all-row-nowrap align-all-spcBtw-ctr">
                    <button mat-raised-button class="session-button button-buy fx-all-100 align-all-ctr-ctr"
                            [routerLink]="['/']">
                        {{"EXCHANGE.SESSIONS.CONTINUE" | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

