export const environment = {
    adultAge: 18,
    production: false,
    host: 'https://test.bitblinx.com/api',
    uploadHostUrl: 'https://test.bitblinx.com',
    socketUrl: 'wss://test.bitblinx.com/ws',
    appTitle: 'Bitblinx',
    authHeaderPrefix: 'sx',
    portalUrl: 'https://test.bitblinx.com',
    chartType: 'tradeview',
    priceMultiplicator: 10000000000,
    quantityMultiplicator: 1000000,
    reCaptchaSiteKey: '6Lfqxm4UAAAAAFUez_DrARHZwCPwe1b9Fqg65wOS',
    version: '1.0.59',
    mt5: {
        enabled: false
    },
    marginTrading: {
        enabled: false
    },
    beErrorLangKey: 'BACKEND_ERRORS',
    maxUploadSizeInMB: 3,
    unavailableCountries: ['US', 'NL'],
    cookie: {
        secure: true,
        domain: 'arag.club'
    },
    firebase: {
        apiKey: 'AIzaSyA38WyespLkjduPzE733fT0ekFM6HNkLjE',
        authDomain: 'testexchangepush.firebaseapp.com',
        databaseURL: 'https://testexchangepush.firebaseio.com',
        projectId: 'testexchangepush',
        storageBucket: 'testexchangepush.appspot.com',
        messagingSenderId: '472555660346',
        appId: '1:472555660346:web:eb8783c9e59c8e67720103',
        measurementId: 'G-EXG88WK1N1'
    },
    sumsub: {
        url: 'https://api.sumsub.com',
        kycStage: 'basic-kyc',
        videoKycStage: 'video-kyc'
    }
};
