<div class="dialog-container fx-all-100 layout-all-col-nowrap">
    <div class="content-box fx-all-100 layout-all-row-wrap">
        {{"EXCHANGE.MODALS.PROVIDE_ADDITIONAL_TAG" | translate}}
    </div>

    <div class="actions-box fx-all-100 layout-all-row-nowrap align-all-fxEnd-fxStr">
        <div class="fx fill-0-1 offset-all-1">
            <button mat-raised-button color="accent" class="button-primary button-auth" (click)="confirm()"
                    style="float: right;">{{"EXCHANGE.MODALS.CONFIRM" | translate}}
            </button>
        </div>
        <div class="fx fill-0-1 offset-all-1">
            <button mat-raised-button color="accent" class="button-red button-auth" (click)="cancel()"
                    style="float: right;margin-right:10px;">{{"EXCHANGE.MODALS.CANCEL" | translate}}
            </button>
        </div>
    </div>
</div>
