import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {UserService} from '../../../services/user.service';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../services/storage.service';
import {LoggerService} from '../../../services/logger.service';
import {AdminService} from '../../../services/admin.service';
import {Subscription} from 'rxjs';
import {ThemeService} from '../../../services/theme.service';
import {SocketStatusType, WsMainService} from '../../../services/wsMain.service';
import {Utl} from '../../../helpers/utl';
import {NotificationService} from '../../../services/notification.service';
import {InfoSnackBarComponent} from '../../info-snackbar/info-snackbar.component';

@Component({
    selector: 'app-main-layout',
    templateUrl: 'main-layout.template.html'
})
export class MainLayoutComponent implements OnInit, OnDestroy {
    role = 'user';
    isAuthenticated = true;
    email = '';
    firstName = '';
    lastName = '';
    verified = '';
    kycSubmitted = '';
    currentYear = 0;
    selectedLang = 'en';
    systemNotificationsEnabled: boolean = false;
    private getCurrentUserSubscription: Subscription;

    availableLangs = [
        {
            'slug': 'en',
            'name': 'English',
            'img': '../../../../../assets/img/flags/en.svg',
            'id': 'langEng'
        },
        {
            'slug': 'de',
            'name': 'Deutsch',
            'img': '../../../../../assets/img/flags/de.svg',
            'id': 'langDe'
        },
        {
            'slug': 'ru',
            'name': 'Русский',
            'img': '../../../../../assets/img/flags/ru.svg',
            'id': 'langRus'
        }
    ];

    themes: any = [
        {
            'color': '#f4f7d1',
            'name': 'light',
            'icon': 'wb_sunny'
        },
        {
            'color': '#314456',
            'name': 'dark',
            'icon': 'brightness_3'
        }
    ];
    currentTheme: any;
    canChangeTheme: boolean = true;

    langs: any = {};

    marketViewPage = false;

    private newUserTradeSubscription: Subscription = null;
    private newOrderChangedSubscription: Subscription = null;
    private wsStatusSubscription: Subscription = null;

    constructor(protected router: Router,
                protected route: ActivatedRoute,
                public translate: TranslateService,
                protected authService: AuthService,
                private userService: UserService,
                private adminService: AdminService,
                private notificationService: NotificationService,
                protected storage: StorageService,
                public snackBar: MatSnackBar,
                private logger: LoggerService,
                private wsMain: WsMainService,
                private themeService: ThemeService) {
        // Translator init
        const browserLang: string = translate.getBrowserLang();

        this.selectedLang = browserLang.match(/en|ru|de/) ? browserLang : 'en';

        if (['en', 'ru', 'de'].includes(localStorage.getItem('lang'))) {
            this.selectedLang = localStorage.getItem('lang');
        } else {
            this.selectedLang = 'en';
        }

        for (const lang of this.availableLangs) {
            this.langs[lang.slug] = lang;
        }

        this.role = this.storage.getItem('role');
        this.verified = this.storage.getItem('verified');
        this.kycSubmitted = this.storage.getItem('kycSubmitted');
        this.translate.use(this.selectedLang);

        this.router.events.subscribe(url => {
            if (url instanceof NavigationEnd) {
                this.marketViewPage = false;
                if (url) {
                    if (url.url.includes('/sessions/market-view')) {
                        this.marketViewPage = true;
                    }
                }
            }
        });
    }

    setLang(lang) {
        setTimeout(() => {
            const resizeEvent = new Event('resize');
            window.dispatchEvent(resizeEvent);
        }, 100);

        localStorage.setItem('lang', lang);
        this.selectedLang = lang;
        this.translate.use(lang);

        if (!this.isAuthenticated) {
            return;
        }

        this.userService.setUserLanguage(lang).subscribe(
            (result) => {
            }
        );
    }

    ngOnInit() {
        this.currentTheme = this.storage.getItem('theme') === 'dark' ? this.themes[1] : this.themes[0];
        this.changeTheme(this.currentTheme.name);

        this.getCurrentYear();
        this.isAuthenticated = this.storage.getItem('isLoggedin') === 'true';

        if (!this.isAuthenticated) {
            if (!this.router.url.includes('sessions/') &&
                !this.router.url.includes('withdraw/') &&
                !this.router.url.includes('payment/') &&
                !this.router.url.includes('page/')) {
                this.router.navigate(['/sessions/signin']);
            }
            return;
        }

        const userName = this.storage.getItem('firstName');
        userName ? this.firstName = userName : this.firstName = '';

        this.email = this.storage.getItem('email');

        this.lastName = this.storage.getItem('lastName');

        if (['en', 'ru', 'de'].includes(localStorage.getItem('lang'))) {
            this.selectedLang = localStorage.getItem('lang');
        } else {
            this.selectedLang = 'en';
        }
        this.translate.use(this.selectedLang);

        this.getCurrentUserSubscription = this.userService.getCurrentUser()
            .subscribe((user) => {
                this.systemNotificationsEnabled = user.properties && user.properties.systemNotificationsEnabled;
            });

        this.wsStatusSubscription = this.wsMain.getSocketStatus().subscribe((status) => {
            if (status === SocketStatusType.CONNECTED) {
                if (this.newUserTradeSubscription) {
                    this.newUserTradeSubscription.unsubscribe();
                    this.newUserTradeSubscription = null;
                }

                if (this.newOrderChangedSubscription) {
                    this.newOrderChangedSubscription.unsubscribe();
                    this.newOrderChangedSubscription = null;
                }
                if (this.isAuthenticated) {
                    this.wsMain.authorizeSocket();
                }
            } else if (status === SocketStatusType.AUTHORIZED) {
                this.newUserTradeSubscription = this.wsMain.subscribeToEvent(['newUserTrade']).subscribe((msg) => {
                    this.onNewUserTrade(msg);
                });
                this.newOrderChangedSubscription = this.wsMain.subscribeToEvent(['orderUpdate']).subscribe((msg) => {
                    this.onNewOrderUpdate(msg);
                });
            }
        });
    }

    onNewUserTrade(msg): void {
        // userId: "5b7868f8669dd4834f90b94e"
        // symbol: "BTC/EUR"
        // tradeId: "BTC-EUR-9-1582279194465"
        // fee: "10.000000"
        // quantity: "-0.100000"
        // price: "10000.0000000000"
        // created: "2020-02-21T09:59:54.518Z"
        // __proto__: Object
        const userTrade = msg.result;

        const dataToShow = {
            title: this.translate.instant('NEW_TRADE'),
            Amount: userTrade.quantity,
            Price: userTrade.price,
            Symbol: userTrade.symbol
        };

        this.snackBar.openFromComponent(
            InfoSnackBarComponent,
            {
                data: dataToShow,
                duration: 4500
            });
    }

    onNewOrderUpdate(msg): void {
        const updatedOrder = msg.result;
        let title = null;
        let price = null;
        let quantity = null;
        let timeout = 0;
        switch (updatedOrder.status) {
            case 'cancelled':
                title = this.translate.instant('ORDER_CANCELED');
                quantity = Utl.formatQuantity(updatedOrder.symbol, updatedOrder.quantity);
                price = Utl.formatPrice(updatedOrder.symbol, updatedOrder.price);
                break;
            case 'new':
                if (updatedOrder.cumQuantity > 0) {
                    return;
                }
                title = this.translate.instant('ORDER_CREATED');
                quantity = Utl.formatQuantity(updatedOrder.symbol, updatedOrder.quantity);
                price = Utl.formatPrice(updatedOrder.symbol, updatedOrder.price);
                break;
            case 'filled':
                title = this.translate.instant('FILLED');
                quantity = Utl.formatQuantity(updatedOrder.symbol, updatedOrder.cumQuantity);
                price = Utl.formatPrice(updatedOrder.symbol, updatedOrder.price);
                if (Number(updatedOrder.averagePrice)) {
                    price = Utl.formatPrice(updatedOrder.symbol, updatedOrder.averagePrice);
                }
                timeout = 2000;
                break;
        }

        const dataToShow = {
            title: title,
            Amount: quantity,
            Price: price,
            Symbol: updatedOrder.symbol
        };

        setTimeout(() => {
            this.snackBar.openFromComponent(
                InfoSnackBarComponent,
                {
                    data: dataToShow,
                    duration: 4500
                });
        }, timeout);

    }

    ngOnDestroy() {
        if (this.getCurrentUserSubscription) {
            this.getCurrentUserSubscription.unsubscribe();
        }

        if (this.wsStatusSubscription) {
            this.wsStatusSubscription.unsubscribe();
            this.wsStatusSubscription = null;
        }

        if (this.newUserTradeSubscription) {
            this.newUserTradeSubscription.unsubscribe();
            this.newUserTradeSubscription = null;
        }

        if (this.newOrderChangedSubscription) {
            this.newOrderChangedSubscription.unsubscribe();
            this.newOrderChangedSubscription = null;
        }
    }

    getCurrentYear() {
        this.currentYear = (new Date()).getFullYear();
    }

    signOut() {
        this.authService.logout()
            .subscribe(
                (result) => {
                    window.location.reload();
                },
                (err) => {
                    this.router.navigate(['/']);
                });
    }

    selectTheme(theme: any) {
        if (this.currentTheme.name === theme.name) {
            return;
        }
        this.changeTheme(theme.name, this.currentTheme.name);
        this.currentTheme = theme;
    }

    changeTheme(newClass: string, oldClass?: string) {
        if (!this.canChangeTheme) {
            return;
        }

        this.storage.setItem('theme', newClass);
        const body = document.body;
        const newClassName = `theme-${newClass}`;
        const oldClassName = `theme-${oldClass}`;

        if (oldClass && body.classList.contains(oldClassName)) {
            body.classList.remove(oldClassName);
        }

        body.classList.add(newClassName);

        this.themeService.setTheme({
            newTheme: newClass,
            oldTheme: oldClass
        });
    }
}
