<div class="logo">
    <a class="head-logo" [routerLink]="['/']"></a>
</div>

<div *ngIf="!isAuthenticated" class="head-menu hidden-sm hidden-xs hidden-menu">
    <app-responsive-menu class="sessions-menu" [items]="marketViewItems"></app-responsive-menu>
</div>

<div *ngIf="isAuthenticated && !marketViewPage && !isMobile" class="head-menu hidden-menu">
    <app-responsive-menu [items]="items"></app-responsive-menu>
</div>

<ul class="nav navbar-nav" *ngIf="isMobile">
    <li class="dropdown languages">
        <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button"
           aria-expanded="false">
            <i class="material-icons">menu</i>
        </a>

        <ul class="dropdown-menu" role="menu" *ngIf="isMobile">
            <li *ngFor="let item of items">
                <a class="language" [routerLink]="[item.link]" routerLinkActive="active">
                    {{ item.name | translate }}
                </a>
            </li>
        </ul>
    </li>
</ul>
