import {Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-approve-cancel',
    templateUrl: 'approve-cancel.component.html'
})
export class ApproveCancelComponent implements OnInit {

    reasonText = '';

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<ApproveCancelComponent>,
                public snackBar: MatSnackBar,
                public translate: TranslateService) {
                    this.reasonText = data.bodyText;
    }

    ngOnInit() {
    }

    cancel() {
        this.dialogRef.close({result: false});
    }

    confirm() {
        this.dialogRef.close({result: true});
    }

}
