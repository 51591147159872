import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import {Utl} from '../../helpers/utl';

@Component({
    selector: 'app-multiple-select',
    templateUrl: 'multiple-select.template.html'
})

export class MultipleSelectComponent implements OnInit {
    @Input() set options(data: string[]) {
        this._options = [];
        this._options = data && data.length ? [...data] : [];
        this._options.unshift('ALL');
    }
    @Input() set selects(data: string[]) {
        this._selects = [];
        this._selects = data && data.length ? [...data] : [];
        if (this._selects.length === this._options.length - 1) {
            this._selects.unshift('ALL');
        }
    }
    @Input() namePath: string;
    @Input() placeholder: string = '';
    @Input() classnames: string = '';
    @Output() output: EventEmitter<string[]> = new EventEmitter();

    _options: string[];
    _selects: string[];
    outputData: string[];

    constructor() {

    }

    ngOnInit(): void {
    }

    checkOption(option: string): void {
        const exists = (this._selects.indexOf('ALL') !== -1);
        if (option === 'ALL') {
            if (exists) { // if 'ALL' option selected
                this.updateOptions(this._options);
            } else { // if 'ALL' option deselected
                this.updateOptions();
            }
        }
        // if all options are selected select also 'ALL' option
        if (this._selects.length === this._options.length - 1 && !exists) {
            this.updateOptions(this._options);
        }
        // if all options are selected and one option is deselecting
        if (option !== 'ALL' && this._selects.length === this._options.length - 1) {
            const options = Utl.removeItemsFromArray(this._selects.splice(0), ['ALL', option]);
            this.updateOptions(options);
        }
        this.makeOutput();
    }

    updateOptions(options?: any) {
        this._selects = options ? options : [];
    }

    makeOutput(): void {
        this.outputData = [];
        this.outputData = [...this._selects];    // cloning array
        this.outputData = Utl.removeItemsFromArray(this.outputData, ['ALL']);
        this.output.emit(this.outputData);
    }
}
