import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material';

@Component({
    selector: 'success-snack-bar',
    templateUrl: 'success-snackbar.component.html',
})
export class SuccessSnackBarComponent implements OnInit {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public snackBarRef: MatSnackBar) {

    }

    close() {
        this.snackBarRef.dismiss();
    }

    ngOnInit() {

    }
}
