import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material';

@Component({
    selector: 'app-another-snack-bar',
    templateUrl: 'info-snackbar.component.html',
})
export class InfoSnackBarComponent {
    infoData: any = [];

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data, public snackBarRef: MatSnackBar) {
        if (!data) {
            return;
        }

        const infoList = Object.keys(data);
        for (const info of infoList) {
            if (info !== 'title' && info !== 'text') {
                const attr = {
                    title: info,
                    value: data[info]
                };
                this.infoData.push(attr);
            }

            if (info === 'text') {
                const attr = {
                    value: data[info]
                };
                this.infoData.push(attr);
            }
        }
    }

    close() {
        this.snackBarRef.dismiss();
    }
}
