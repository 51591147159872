import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NetworkHelper} from '../helpers/network.helper';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';


@Injectable()
export class Mt5Service {
    private readonly hostUrl: string;

    constructor(private router: Router, private networkHelper: NetworkHelper) {
        this.hostUrl = environment.host;
    }

    getCurrencies(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/mt5/currencies`, {});
    }

    getUserAccounts(currency, platform, type, userId = null, page, itemsPerPage, email = null): Observable<any> {
        const data = {
            currency: currency,
            platform: platform,
            type: type,
            itemsPerPage: itemsPerPage
        };

        if (email) {
            data['email'] = email;
        }

        if (userId) {
            data['userId'] = userId;
        }

        return this.networkHelper.get(`${this.hostUrl}/mt5/accounts`, data);
    }

    getAccountById(accountId): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/mt5/accounts/${accountId}`);
    }

    getTransactions(userId = null, accountId = null, startDate, endDate, page, itemsPerPage, currency, type): Observable<any> {
        const data = {
            startDate: startDate,
            endDate: endDate,
            page: page,
            itemsPerPage: itemsPerPage,
            currency: currency,
            type: type
        };

        if (userId) {
            data['userId'] = userId;
        }

        if (accountId) {
            data['accountId'] = accountId; 
        }


        return this.networkHelper.get(`${this.hostUrl}/mt5/transactions`, data);
    }

    getTransactionsCsv(userId = null, accountId = null, startDate, endDate, currency, type): Observable<any> {
        const data = {
            startDate: startDate,
            endDate: endDate,
            currency: currency,
            type: type
        };

        if (userId) {
            data['userId'] = userId;
        }

        if (accountId) {
            data['accountId'] = accountId; 
        }

        return this.networkHelper.get(`${this.hostUrl}/mt5/csv/transactions`, data);
    }

    regeneratePass(accountId): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/mt5/regenerate-passwords/${accountId}`, {});
    }

    createAccount(currency, platform, type): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/mt5/accounts`, {
            currency: currency,
            platform: platform,
            type: type
        });
    }

    deposit(accountId, amount): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/mt5/deposit`, {
            accountId: accountId,
            amount: amount
        });
    }

    withdrawal(accountId, amount): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/mt5/withdraw`, {
            accountId: accountId,
            amount: amount
        });
    }


}
