<mat-form-field [className]="classnames">
    <mat-select [placeholder]="placeholder | translate"
                [(ngModel)]="_selects" multiple>
        <mat-select-trigger>
            <span *ngIf="_selects?.length > 0 && _selects?.length < _options.length">
                {{_selects?.length ? (namePath + _selects[0] | translate) : ''}}
            </span>
            <span *ngIf="_selects?.length > 1 && _selects?.length < _options.length">
                (+{{_selects?.length - 1}} {{(_selects?.length === 2 ? 'OTHER' : 'OTHERS') | lowercase | translate}})
            </span>
            <span *ngIf="_selects?.length === _options.length">
                {{'ALL' | translate}}
            </span>
        </mat-select-trigger>
        <mat-option *ngFor="let po of _options" [value]="po" (click)="checkOption(po)">
            <span>{{ namePath + po | translate}}</span>
        </mat-option>
    </mat-select>
</mat-form-field>
