import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NetworkHelper} from '../helpers/network.helper';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';
import * as moment from 'moment';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class CurrencyService {
    private static currenciesCache: any = {};
    private static symbolsCache: any = {};

    private readonly hostUrl: string;

    private firstLoadCurrencies: boolean = true;
    private currenciesAreChanged: boolean = true;
    private currencies: BehaviorSubject<any[]> = null;

    private firstLoadSymbols: boolean = true;
    private symbolsAreChanged: boolean = true;
    private symbols: BehaviorSubject<any[]> = null;

    static getCurrenciesFromCache(): any {
        return CurrencyService.currenciesCache;
    }

    static getFromCache(currency: string): any {
        return CurrencyService.currenciesCache[currency];
    }

    static getSymbolsFromCache(): any[] {
        return CurrencyService.symbolsCache;
    }

    constructor(private router: Router, private networkHelper: NetworkHelper) {
        this.hostUrl = environment.host;
    }

    getSymbols(): BehaviorSubject<any[]> {
        if (!this.symbolsAreChanged) {
            return this.symbols;
        }

        if (this.firstLoadSymbols) {
            this.firstLoadSymbols = false;
            this.symbols = new BehaviorSubject<any[]>([]);
        }

        if (this.symbolsAreChanged) {
            this.symbolsAreChanged = false;

            this._getSymbols()
                .subscribe((result) => {
                        result.result.forEach((item) => {
                            CurrencyService.symbolsCache[item.symbol] = item;
                        });
                        this.symbols.next(result.result);
                    },
                    (error) => {
                        this.symbols.error(error); // propagate up
                    });
        }

        return this.symbols;
    }

    private _getSymbols(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/symbols`);
    }

    getPrices(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/prices`);
    }

    getCurrencies(): BehaviorSubject<any[]> {
        if (!this.currenciesAreChanged) {
            return this.currencies;
        }

        if (this.firstLoadCurrencies) {
            this.firstLoadCurrencies = false;
            this.currencies = new BehaviorSubject<any[]>([]);
        }

        if (this.currenciesAreChanged) {
            this.currenciesAreChanged = false;

            this._getCurrencies()
                .subscribe((result) => {
                    for (const item of result.result) {
                        CurrencyService.currenciesCache[item.symbol] = item;
                    }

                        this.currencies.next(result.result);
                    },
                    (error) => {
                        this.currencies.error(error); // propagate up
                    });
        }

        return this.currencies;
    }

    private _getCurrencies(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/currencies`);
    }

    public getWalletTransactions(currency: string, page: number, itemsPerPage: number, startDate: number,
                                 endDate: number, type: string, status: string, userId: string,
                                 includePending: boolean, containTotals: boolean): Observable<any> {
        const data = {
            page: page,
            itemsPerPage: itemsPerPage,
            startDate: startDate,
            endDate: endDate
        };

        if (userId) {
            data['userId'] = userId;
        }

        if (type) {
            data['type'] = type;
        }

        if (status) {
            data['status'] = status;
        }

        if (currency !== 'any') {
            data['currency'] = currency;
        }

        if (includePending) {
            data['includePending'] = true;
        }

        if (containTotals) {
            data['containTotals'] = true;
        }

        return this.networkHelper.get(`${this.hostUrl}/user/transactions`, data);
    }

    getWalletTransactionsCsv(currency: string, startDate: number, endDate: number, type: string = null,
                             status: string = null, userId: string = null): Observable<any> {
        const data = {
            startDate: startDate,
            endDate: endDate
        };

        if (userId) {
            data['userId'] = userId;
        }

        if (type) {
            data['type'] = type;
        }

        if (currency !== 'any') {
            data['currency'] = currency;
        }

        data['timeZoneShift'] = moment().utcOffset();

        return this.networkHelper.get(`${this.hostUrl}/user/csv/transactions`, data);
    }

    addCurrency(data): Observable<any> {
        this.currenciesAreChanged = true;
        return this.networkHelper.post(`${this.hostUrl}/admin/add-currency`, data);
    }

    getAllCurrencies(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/admin/currencies`);
    }

    getAllSymbols(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/admin/pair`);
    }

    addSymbol(data): Observable<any> {
        this.symbolsAreChanged = true;
        return this.networkHelper.post(`${this.hostUrl}/admin/add-pair`, data);
    }
}
