import {AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import {TranslateService, TranslationChangeEvent} from '@ngx-translate/core';
import {StorageService} from '../../services/storage.service';

@Component({
    selector: 'app-responsive-menu',
    templateUrl: './responsive-menu.component.html',
    styleUrls: ['./responsive-menu.component.css']
})
export class ResponsiveMenuComponent implements OnInit, AfterViewInit {
    @Input() items;
    onResizeController = new Subject<string>();
    isAuthenticated: boolean;
    @ViewChild('menu', {static: false}) el: ElementRef;
    @ViewChild('more', {static: false}) moreEl: ElementRef;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.onResizeController.next(event);
    }

    constructor(private elRef: ElementRef,
                protected storage: StorageService) {
        this.onResizeController
            .debounceTime(10)
            .distinctUntilChanged()
            .subscribe(result => {
                this.calcMenuWidth();
            });
    }

    ngOnInit() {
        this.isAuthenticated = this.storage.getItem('isLoggedin') === 'true';
    }

    ngAfterViewInit() {
        setTimeout(() => { this.calcMenuWidth(); }, 5000);
    }

    calcMenuWidth(preLast = null) {
        let calculateAgain = false;
        const contWidth = this.el.nativeElement.clientWidth;

        const moreClasses = window.getComputedStyle(this.moreEl.nativeElement);
        const moreWith = this.moreEl.nativeElement.clientWidth + 1 + parseInt(moreClasses.marginRight, 10) + parseInt(moreClasses.marginLeft, 10);

        let elementsWidth =  0;

        for (const menuItem of this.el.nativeElement.children) {
            const menuItemStyles = window.getComputedStyle(menuItem);
            const marginRight = parseInt(menuItemStyles.marginRight, 10);
            const marginLeft = parseInt(menuItemStyles.marginLeft, 10);
            elementsWidth = elementsWidth + menuItem.clientWidth + marginRight + marginLeft + 1;
        }

        const availableSpace = contWidth - moreWith;


        if (elementsWidth > availableSpace) {
            let lastItem = this.elRef.nativeElement.querySelectorAll('.head-menu-item.real-menu-item');
            lastItem = lastItem[lastItem.length - 1];

            if (preLast === lastItem) {
                return;
            }

            if (lastItem) {
                const lastItemStyles = window.getComputedStyle(lastItem);
                const marginRight = parseInt(lastItemStyles.marginRight, 10);
                const marginLeft = parseInt(lastItemStyles.marginLeft, 10);
                lastItem.setAttribute('data-width', lastItem.clientWidth +  marginRight + marginLeft + 1);
                lastItem.className = 'head-menu-item';

                const hiddenMenu = document.getElementById('dropdown-header-menu');
                hiddenMenu.insertBefore(lastItem, hiddenMenu.childNodes[0]);
                calculateAgain = lastItem;
            }
        } else {
            const moreElements = this.elRef.nativeElement.querySelectorAll('.dropdown-header-menu>div');
            if (moreElements.length > 0) {
                const lastMoreElement = moreElements[0];
                if (preLast === lastMoreElement) {
                    return;
                }

                const moreLastElementLength = lastMoreElement.getAttribute('data-width');
                if (elementsWidth + Number(moreLastElementLength) < availableSpace) {
                    lastMoreElement.className = 'head-menu-item real-menu-item';
                    const currentMenuItems = this.elRef.nativeElement.querySelectorAll('#menu-header>div');
                    document.getElementById('menu-header').insertBefore(lastMoreElement, currentMenuItems[currentMenuItems.length - 1]);
                    calculateAgain = lastMoreElement;
                }
            }
        }

        const moreItems = this.elRef.nativeElement.querySelectorAll('.dropdown-header-menu > div');

        if (moreItems.length) {
            this.moreEl.nativeElement.style.display = 'inline-block';
        } else {
            this.moreEl.nativeElement.style.display = 'none';
        }

        if (calculateAgain) {
            this.calcMenuWidth(calculateAgain);
        }

    }

    translationCompleted() {
        this.onResizeController.next();
    }

}
