import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-kyc-admin-header',
    templateUrl: 'kyc-admin-header.component.html'
})
export class KycAdminHeaderComponent implements OnInit {
    items = [
        {
            'link': '/admin/users',
            'materialicon': 'account_circle',
            'name': 'EXCHANGE.ADMIN.LAYOUT_MENU.USERS',
            'exact': true
        }
    ];
    constructor() {
    }

    ngOnInit() {
    }
}
