<div class="notifications-container"
     (clickElsewhere)="closeOnOutsideClick($event)">
    <button mat-button class="notify-toggler"
            (click)="onToggleNotifications()">
        <span class="notify-icon">
            <span class="material-icons ring-icon">notification_important</span>
            <span class="notification-badge" *ngIf="unseenTotal">{{unseenTotal}}</span>
        </span>
    </button>
    <div class="notify-list-box" [ngClass]="{'opened': showNotifications}">
        <div class="notify-list" (scroll)="onScroll($event)">
            <div *ngFor="let note of notifications; let idx = index;"
                 class="notify-list-item">
                <div class="item-wrapper"
                     [ngClass]="{'as-read': !note.read}"
                     (click)="notificationAction(note)">
                    <div class="notify-avatar-wrapper">
                        <div class="initials" [ngStyle]="{'backgroundColor': note.color}">
                            {{note.shortName}}
                        </div>
                    </div>
                    <div class="notify-text-box" [ngClass]="{'as-read': !note.read}">
                        <p class="text" [innerHtml]="note.text"></p>
                        <p class="date" [ngClass]="{'as-read': !note.read}" [innerHTML]="note.created | timeAgo"></p>
                    </div>
                </div>
                <button class="notify-actions-button" mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="onChangeReadStatus(!note.read, note)">
                        <span *ngIf="!note.read">{{'NOTIFICATION.MARK_AS_READ' | translate}}</span>
                        <span *ngIf="note.read">{{'NOTIFICATION.MARK_AS_UNREAD' | translate}}</span>
                    </button>
                    <button mat-menu-item (click)="onRemove(note.id)">
                        <span>{{'NOTIFICATION.REMOVE' | translate}}</span>
                    </button>
                </mat-menu>
            </div>
        </div>
        <div class="notify-list-footer">
            <button mat-button class="mark-all"
                    (click)="onChangeReadStatus(true)"
                    [disabled]="!unreadTotal">
                {{'NOTIFICATION.MARK_ALL_AS_READ' | translate}}
            </button>
        </div>
    </div>
</div>
