import {MatDialogRef, MatSelect, MatSnackBar} from '@angular/material';
import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {Reason, REASONS} from './disapprove.reasons';
import {FormControl} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-reason-disapprove',
    templateUrl: 'disapprove-modal.template.html'
})
export class ReasonComponent implements OnInit, AfterViewInit, OnDestroy {
    env: any = environment;
    reasons: Reason[] = REASONS;
    public reasonCtrl: FormControl = new FormControl();
    public reasonFilterCtrl: FormControl = new FormControl();
    public filteredReasons: ReplaySubject<Reason[]> = new ReplaySubject<Reason[]>();

    protected _onDestroy = new Subject<void>();

    @ViewChild('reasonSelect', {static: true}) reasonSelect: MatSelect;


    constructor(public dialogRef: MatDialogRef<ReasonComponent>,
                public snackBar: MatSnackBar,
                public translate: TranslateService) {
    }

    ngOnInit(): void {
        this.reasonCtrl.setValue(this.reasons[0]);
        this.filteredReasons.next(this.reasons.slice());
        this.reasonFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterReasons();
            });
    }

    ngAfterViewInit(): void {
        this.setInitialValue();
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    setInitialValue() {
        this.filteredReasons
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.reasonSelect.compareWith = (a: Reason, b: Reason) => a && b && a.id === b.id;
            });
    }

    filterReasons() {
        if (!this.reasons) {
            return;
        }

        let search = this.reasonFilterCtrl.value;
        if (!search) {
            this.filteredReasons.next(this.reasons.slice());
            return;
        }
        if (!this.reasons.some(reason => reason.id === 0)) {
            this.reasons = [{name: '', id: 0}].concat(this.reasons);
        }

        this.reasons[0].name = search;

        search = search.toLowerCase();

        this.filteredReasons.next(
            this.reasons.filter(reason => reason.name.toLowerCase().indexOf(search) > -1)
        );

    }

    cancel() {
        this.dialogRef.close({result: false});
    }

    confirm() {
        this.dialogRef.close({result: true, message: this.reasonCtrl.value.name});
    }
}
