import {Component, HostListener, OnInit} from '@angular/core';
import {NotificationService} from '../../services/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit {
    notifications: any[] = [];
    page: number = 1;
    itemsPerPage: number = 5;
    noScroll: boolean = false;
    unseenTotal: number = 0;
    unreadTotal: number = 0;
    showNotifications: boolean = false;
    notificationsCaller: any;
    notificationsInterval: number = 60 * 1000;
    canPing: boolean = true;

    static getRandomColor(id: string): string {
        let number;
        const colors: string[] = [
            '#FFCA28',
            '#FFA726',
            '#FB8C00',
            '#D4E157',
            '#C0CA33',
            '#AFB42B',
            '#69F0AE',
            '#1DE9B6',
            '#66BB6A',
            '#29B6F6',
            '#26C6DA',
            '#1565C0',
            '#CE93D8',
            '#E040FB',
            '#FF8A65',
            '#EF5350'
        ];

        if (!id) {
            return colors[colors.length - 1];
        }

        const symbols = id.split('');
        for (let s = symbols.length - 1; s >= 0; --s) {
            if (symbols[Number(symbols[s])]) {
                number = symbols[s];
                break;
            }
        }
        return colors[number];
    }

    static resolveShortName(note: any): string {
        let shortName = '';
        if (note.args.user.firstName && note.args.user.firstName.length) {
            shortName = note.args.user.firstName.charAt(0);
        }
        if (note.args.user.lastName && note.args.user.lastName.length) {
            shortName += note.args.user.lastName.charAt(0);
        }
        if (!shortName.length) {
            shortName = note.args.user.email.charAt(0);
        }
        return shortName;
    }

    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !this.noScroll) {
           ++this.page;
           this.getNotifications();
        }
    }

    constructor(private notificationService: NotificationService,
                public translate: TranslateService,
                public snackBar: MatSnackBar,
                private router: Router) {
    }

    ngOnInit() {
        this.getNotifications();
    }

    getNotifications(action?: 'delete'): void {
        let page = this.page;
        let itemsPerPage = this.itemsPerPage;

        if (action === 'delete') {
            page = 1;
            itemsPerPage = this.page * this.itemsPerPage;
        }

        this.notificationService.getAll(page, itemsPerPage)
            .subscribe(
                (result) => {
                    if (result.result.notifications.length) {
                        if (action === 'delete') {
                            this.notifications = result.result.notifications;
                        } else {
                            if (page === 1 && result.result.unseenTotal > 0) {
                                this.playNotificationAudio('new_kyc'); // play something
                            }
                            this.notifications = this.notifications.concat(result.result.notifications);
                        }
                    } else {
                        --this.page;
                        this.noScroll = true;
                    }
                    const unseenPrevTotal = this.unseenTotal;
                    this.unseenTotal = result.result.unseenTotal;
                    this.unreadTotal = result.result.unreadTotal;

                    if (this.unseenTotal && this.unseenTotal !== unseenPrevTotal) {
                        this.canPing = true;
                    }

                    this.notifications.map((n) => {
                        if (!n.color) {
                            n.color = NotificationsComponent.getRandomColor(n.id);
                        }
                        n.text = this.resolveText(n);
                        n.shortName = NotificationsComponent.resolveShortName(n);
                        return n;
                    });
                },
                (err) => {
                    this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                });
    }

    playNotificationAudio(type: 'new_kyc' | 'new_payout'): void {
        if (!this.canPing) {
            return;
        }

        const audio = new Audio();
        audio.src = `../../../../assets/audio/${type}.mp3`;
        audio.load();
        audio.pause();
        audio.play();
        this.canPing = false;
    }

    markAllAsSeen(): void {

        this.notificationService.changeSeenStatus()
            .subscribe(
                (result) => {
                    this.unseenTotal = 0;
                },
                (err) => {
                    this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                });
    }

    onChangeReadStatus(read: boolean, item?: any): void {
        let ids = [];
        if (item && item.id) {
            ids = [item.id];
        } else {
            this.unreadTotal = 0;
        }

        this.notificationService.changeReadStatus(ids, read)
            .subscribe(
                (result) => {
                    if (item) {
                        item.read = read;
                        if (item.read) {
                            --this.unreadTotal;
                        } else {
                            ++this.unreadTotal;
                        }
                    } else {
                        this.markAllAsRead();
                    }
                },
                (err) => {
                    this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                });
    }

    markAllAsRead(): void {
        for (const note of this.notifications) {
            note.read = true;
        }
    }

    startNotificationsJob(): void {
        this.notificationsCaller = setInterval(() => {
            this.resetData();
            this.getNotifications();
        }, this.notificationsInterval);
    }

    endNotificationsJob(): void {
        if (this.notificationsCaller) {
            clearInterval(this.notificationsCaller);
        }
    }

    resetData(): void {
        this.page = 1;
        this.unreadTotal = 0;
        this.notifications = [];
        this.noScroll = false;
    }

    onRemove(id: string): void {
        this.notificationService.markAsRemoved(id)
            .subscribe(
                (result) => {
                    this.getNotifications('delete');
                },
                (err) => {
                    this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                });
    }

    onToggleNotifications(): void {
        this.showNotifications = !this.showNotifications;
        this.markAllAsSeen();

        if (!this.showNotifications) {
            this.startNotificationsJob();
        } else {
            this.endNotificationsJob();
        }
    }

    closeOnOutsideClick(event: any): void {
        const exceptionClasses = ['mat-menu-content', 'mat-menu-item', 'ng-star-inserted'];
        const targetElement = event.target;
        if (this.showNotifications && !exceptionClasses.includes(targetElement.className)) {
            this.onToggleNotifications();
        }
    }

    resolveText(note: any): string {
        let text: string = '';
        switch (note.type) {
            case 'pending_kyc':
                text = this.translate.instant(
                    'NOTIFICATION.MESSAGES.PENDING_KYC',
                    {
                        firstName: note.args.user.firstName,
                        lastName: note.args.user.lastName,
                        email: note.args.user.email
                    });
                break;
            case 'pending_payout':
                text = this.translate.instant(
                    'NOTIFICATION.MESSAGES.PENDING_PAYOUT',
                    {
                        quantity: note.args.payout.quantity,
                        currency: note.args.payout.currency,
                        firstName: note.args.user.firstName,
                        lastName: note.args.user.lastName,
                        email: note.args.user.email
                    });
                break;
        }
        return text;
    }

    notificationAction(note: any): void {
        this.onChangeReadStatus(true, note);
        this.onToggleNotifications();

        if (note.type === 'pending_kyc') {
            this.router.navigate(['/admin/user', note.args.userId]);
        } else if (note.type === 'pending_payout') {
            this.router.navigate(['/admin/payouts']);
        }
    }
}
