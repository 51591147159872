<router-outlet></router-outlet>


<div class="downloader-panel">
    <div class="download-app" *ngIf="downloadLink">
        <div class="close-download" (click)="closeDownload();">
            <b>X</b>
        </div>

        <div class="image">
            <img src="/assets/img/bitblinx-app.svg">
        </div>

        <div class="download-button">
            <a id="app-link" target="_blank" href="{{downloadLink}}">Download</a>
        </div>
    </div>
</div>