<div class="dialog-container fx-all-100 layout-all-col-nowrap">
    <div class="header-box flex layout-all-row-wrap align-all-spcBtw-ctr">
        <div class="header-text fx-all-100">
            <p>{{'EXCHANGE.WALLETS.REDEEM_COUPON' | translate}}</p>
        </div>
    </div>

    <div class="content-box fx-all-100 layout-all-row-wrap">
        <div class="fx-all-100 layout-all-col-nowrap">
            <div class="flex">
                <mat-form-field class="add-currency-field fx-all-100">
                    <input matInput
                           name="card_number"
                           type="text"
                           placeholder="{{'EXCHANGE.WALLETS.CARD_NUMBER' | translate}}"
                           [(ngModel)]="cardNumber">
                    <span class="dashboard-field-icon icon icon-edit"></span>
                </mat-form-field>
            </div>
            <div class="flex">
                <mat-form-field class="add-currency-field fx-all-100">
                    <input matInput
                           name="pin"
                           type="text"
                           placeholder="{{'EXCHANGE.WALLETS.PIN' | translate}}"
                           [(ngModel)]="pinCode">
                    <span class="dashboard-field-icon icon icon-edit"></span>
                </mat-form-field>
            </div>

            <div class="flex">
                <div class="fx-all-100 align-all-fxEnd-ctr bb-coupon">
                    <a href="https://bitblinx.com/bitblinx-coupon/" target="_blank">{{'EXCHANGE.WALLETS.BUY_COUPON' | translate}}</a>
                </div>
            </div>

            <!--
            <div class="flex">
                <mat-form-field fxFlex="100">
                    <mat-select [(value)]="selectedCurrency"
                                [placeholder]="'EXCHANGE.WALLETS.CURRENCY' | translate">
                        <mat-option class="mat-selector-option"
                                    *ngFor="let currency of currencies"
                                    [value]="currency.symbol"
                                    [ngClass]="{'hidden': !currency.isCrypto || !currency.depositActive}">
                            {{currency.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            -->
        </div>
    </div>

    <div class="actions-box fx-all-100 layout-all-row-nowrap align-all-fxEnd-fxStr">
        <div class="fx fill-0-1">
            <button mat-raised-button
                    class="reject-button-filled shadowed tall-button"
                    (click)="close()">
                {{'EXCHANGE.ADMIN.SETTINGS.CANCEL' | translate}}
            </button>
        </div>
        <div class="fx fill-0-1 offset-all-1">
            <button mat-raised-button
                    class="approve-button-filled shadowed tall-button"
                    (click)="redeem()">
                {{'EXCHANGE.WALLETS.REDEEM' | translate}}
            </button>
        </div>
    </div>
</div>
