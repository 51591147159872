<div class="maintenance"
     *ngIf="maintenance.maintenance && !maintenancePage && !hideNotification"
     [ngClass]="{'notification' : maintenance.maintenanceType === 'notification',
                'active': maintenance.maintenanceType === 'maintenance'}"
     [innerHTML]="maintenance.maintenanceMessage">
    <div *ngIf="maintenance.maintenanceType === 'notification'"
         class="close-maintenance"
         (click)="closeNotification()">
        <i class="material-icons">close</i>
    </div>
</div>
