import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS, RecaptchaSettings} from 'ng-recaptcha';
import {CookieService} from 'ngx-cookie-service';
import {rootRouterConfig} from './app.routes';
import {AppComponent} from './app.component';
import {GuestService} from './services/guest.service';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {AppCommonModule} from './components/app-common.module';
import {StorageService} from './services/storage.service';
import {RoutePartsService} from './services/route-parts.service';
import {AuthService} from './services/auth.service';
import {UserService} from './services/user.service';
import {AdminService} from './services/admin.service';
import {TvService} from './services/tv.service';
import {CurrencyService} from './services/currency.service';
import {DataService} from './services/data.service';
import {NetworkHelper} from './helpers/network.helper';
import {TokenInterceptor} from './services/token.interceptor';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {ClipboardService} from './services/clipboard.service';
import {LoggerService} from './services/logger.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from '../environments/environment';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RatesService} from './services/rates.service';
import {QRCodeModule} from 'angular2-qrcode';
import {UploadService} from './services/upload.service';
import {OrderService} from './services/order.service';
import {TFAService} from './services/tfa.service';
import {ChartService} from './services/chart.service';
import {FormsModule} from '@angular/forms';
import {ContactMessageService} from './services/contact-message.service';
import {MaintenanceService} from './services/maintenance.service';
import {WithdrawActivateComponent} from './views/withdraw-activate/withdraw-activate.component';
import {MatButtonModule, MatPaginatorIntl} from '@angular/material';
import {PaginatorIntlService} from './components/mat-paginator/paginator-intl';
import {WebsocketModule} from './websocket/websocket.module';
import {SettingsService} from './services/settings.service';
import {MarginService} from './services/margin.service';
import {Mt5Service} from './services/mt5.service';
import {ThemeService} from './services/theme.service';
import {VersionCheckService} from './services/version-check.service';
import {VoucherManager} from './services/voucher-manager';
import {VoucherMiddlewareComponent} from './views/sessions/voucher-middleware/voucher-middleware.component';
import {WsMainService} from './services/wsMain.service';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireModule} from '@angular/fire';
import {FirebaseMessagingService} from './services/firebase.messaging.service';
import {DynamicScriptLoaderService} from './services/dyncamic-script-loader.service';
import {NotificationService} from './services/notification.service';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?version=' + environment.version);
}

@NgModule({
    declarations: [
        AppComponent,
        WithdrawActivateComponent,
        VoucherMiddlewareComponent
    ],
    imports: [
        BrowserModule,
        AppCommonModule,
        FlexLayoutModule,
        MatButtonModule,
        BrowserAnimationsModule,
        QRCodeModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        RouterModule.forRoot(rootRouterConfig, {
            useHash: false
        }),
        WebsocketModule.config({
            url: environment.socketUrl
        }),
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        BrowserAnimationsModule,
    ],
    providers: [
        GuestService,
        StorageService,
        RoutePartsService,
        AuthService,
        UserService,
        AdminService,
        TvService,
        UploadService,
        RatesService,
        CurrencyService,
        DataService,
        NetworkHelper,
        LoggerService,
        TranslateService,
        ClipboardService,
        OrderService,
        NotificationService,
        TFAService,
        ContactMessageService,
        MaintenanceService,
        SettingsService,
        MarginService,
        ChartService,
        CookieService,
        WsMainService,
        VoucherManager,
        {
            provide: MatPaginatorIntl,
            useFactory: (translate) => {
                const service = new PaginatorIntlService();
                service.injectTranslateService(translate);
                return service;
            },
            deps: [TranslateService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {siteKey: environment.reCaptchaSiteKey} as RecaptchaSettings
        },
        {
            provide: RECAPTCHA_LANGUAGE,
            useValue: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
        },
        Mt5Service,
        ThemeService,
        VersionCheckService,
        DynamicScriptLoaderService,
        FirebaseMessagingService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
