<div id="menu-header" [ngClass]="{'sessions-menu': !isAuthenticated}" #menu>
    <div class="head-menu-item real-menu-item" *ngFor="let item of items; let last = last;"
         (DOMSubtreeModified)="translationCompleted()">
        <a [routerLink]="[item.link]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: item.exact }">
            <i class="material-icons" *ngIf="item.materialicon">{{item.materialicon}}</i> {{ item.name | translate}}
        </a>
    </div>

    <div class="head-menu-item" id="moreItems" #more style="display: none; margin-right: 0 !important;">
        <div class="dropdown">
            <a id="dLabel" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
                <span class="caret"></span>
            </a>
            <ul class="dropdown-menu dropdown-header-menu" aria-labelledby="dLabel" id="dropdown-header-menu">

            </ul>
        </div>
    </div>
</div>
