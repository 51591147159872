import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-check-by-provider',
    templateUrl: 'check-by-provider-modal.template.html'
})
export class CheckByProviderComponent /*implements OnInit */{
    @Output() tiersChanged: EventEmitter<any> = new EventEmitter();

    selectTiers: number[] = [];
    tiers: number[] = [1, 2, 3];

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<CheckByProviderComponent>,
                public snackBar: MatSnackBar,
                public translate: TranslateService) {
        for (let tier = 1; tier <= this.tiers.length; ++tier) {
            this.selectTiers.push(tier);
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    confirm() {
        this.dialogRef.close(this.selectTiers);
    }

    onTiersChanged(event: any) {
        this.selectTiers = event;
        this.tiersChanged.emit(this.selectTiers);
    }
}
