import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {LoggerService} from './logger.service';

@Injectable()
export class VersionCheckService {
    env: any = environment;
    filePath: string = '/assets/version.json';
    currentVersion: string = '';

    constructor(private http: HttpClient,
                private logger: LoggerService) {

    }

    public initVersionCheck(minutes: number) {
        const frequency = minutes * 1000 * 60;
        this.checkVersion();
        setInterval(() => {
            this.checkVersion();
        }, frequency);
    }

    private checkVersion() {
        const now = new Date().getTime();
        this.http.get(`${this.filePath}?timestamp=${now}`)
            .subscribe((res: any) => {
                if (!res.version) {
                    return;
                }

                if (!this.currentVersion) {
                    this.currentVersion = res.version;
                    return;
                }

                if (this.currentVersion < res.version) {
                    this.logger.error(`Version change detected ${this.currentVersion}=>${res.version}, reloading`);
                    window.location.reload();
                }
            }, (error) => {
                this.logger.error(error.message);
            } );
    }
}
