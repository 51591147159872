import {Component, OnDestroy, OnInit} from '@angular/core';
import {CurrencyService} from './services/currency.service';
import {VersionCheckService} from './services/version-check.service';
import {VoucherManager} from './services/voucher-manager';
import {StorageService} from './services/storage.service';

function _window() {
    // return the global native browser window object
    return window;
 }

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
    title = 'exchange';
    message;
    
    windowRef;
    downloadLink;

    constructor(private currencyService: CurrencyService,
                private versionChecker: VersionCheckService,
                private voucherManager: VoucherManager,
                private storageService: StorageService) {
        currencyService.getCurrencies();
        currencyService.getSymbols();
        versionChecker.initVersionCheck(5);

        this.windowRef = _window();
    }

    ngOnInit() {
        this.voucherManager.init();
        if (this.storageService.getItem('download-closed') !== 'true') {
            this.detectMobileOs();
        }
    }

    detectMobileOs() {
        const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !this.windowRef.MSStream;

        if (iOS) {
            this.downloadLink = 'https://apps.apple.com/us/app/bitblinx-crypto-exchange/id1492248968';
        } else {
            this.downloadLink = 'https://play.google.com/store/apps/details?id=com.bitblinx.exchange';
        }
    }

    closeDownload() {
        this.downloadLink = null;
        this.storageService.setItem('download-closed', 'true');
    }
}
